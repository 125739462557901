@import url(https://fonts.googleapis.com/css2?family=Archivo+Black:wght@400&family=Cormorant:wght@400;500;600&family=Inter:wght@200;300;400;500;600;700;800;900&family=Plus+Jakarta+Sans:ital,wght@0,400;0,500;0,700;0,800;1,600&display=swap);
@import url(construction.css);
*,
ul {
  padding: 0;
  margin: 0;
}
html{
  overflow-x: none;
}
.ant-select-item-option-content{
  align-items: center;
}
.heartbeat {
  animation: heartbeat 1s infinite;
  transform-origin: center;
}

@keyframes heartbeat {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}
body {
  -webkit-touch-callout: none; /* Disable selection */
  -webkit-user-select: none; /* Disable selection */
  -khtml-user-select: none; /* Disable selection */
  -moz-user-select: none; /* Disable selection */
  -ms-user-select: none; /* Disable selection */
  user-select: none; /* Disable selection */
}

button{
  border: none;
  background-color: inherit;
}
input {
  -webkit-text-size-adjust: 100%;
}

body{
  overflow-y: hidden;
}
body,
p {
  font-size: 16px;
}
.error-message {
  color: red !important;
  margin-top: 5px;
}
:where(.css-dev-only-do-not-override-1b0bdye).ant-carousel .slick-slider .slick-track, :where(.css-dev-only-do-not-override-1b0bdye).ant-carousel .slick-slider .slick-list{
  overflow-x: hidden !important;
}
:where(.css-1b0bdye).ant-carousel .slick-slider .slick-track, :where(.css-1b0bdye).ant-carousel .slick-slider .slick-list{
  overflow-x: hidden !important;
}
.emailsent{
  color: #0A0A0A;
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 160% */
}
.about-area .container{
  max-width: 1559px !important;
}
.marginabove{
  margin-bottom: 10px;
  margin-bottom: 10px;
    position: absolute;
    bottom: 0px;
    left: 23px;
    padding-bottom: 35px;
}
.loginhed{
  color: #2C292A;

font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 800;
line-height: 120%; /* 28.8px */
letter-spacing: 1.2px;
}
.passwordwarning{
  color: #2C292A;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}
.bg {
  fill: #FBB723 !important;
  cursor: pointer;
}
.map-path {
  fill: #f9f9f9; 
  transition: fill 0.3s; 
}

.map-path:hover {
  fill: #e9c068 !important; 
  cursor: pointer;
}
.statesbox{
  width: 40px;
  height: 40px;
font-size: 16px;
font-weight: 800;
line-height: 24px;
border: 2px solid black;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
}
.boxcontainer{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  width: 100vw;
  justify-content: center;
}
.forflags{
  width: 114px;
height: 25px;
flex-shrink: 0;
border-radius: 7px;
border: 1px solid rgba(210, 210, 210, 0.00);
background: #E8E8E8;
}
.forflags img{
width: 100%;
margin: auto;
}
.custom-popover .ant-popover-inner{
  padding: 0;
}
.stick-image{
  height: 80px;
  
}
.logbtn{
  display: flex;

height: 48px;
width: 100%;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 8px;
background: #FBB723;
color: #2C292A;

font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 150% */
letter-spacing: 0.32px;
}
.preplogin{
  max-width: 90vw;
  margin: auto;
  padding-top: 20px;
}
.card-custom{
  font-size: 15px;
  height: 350px;
}

.feedimage{
  width: 56px;
  height: 56px;
}
.mediumcreenhero{
  display: none;
}
.successcontent{
  display: flex!important;
  justify-content: center;
  column-gap: 10px;
  align-items: center;
  margin-top: 20px;
}
.error-border{
  border: 1px solid red !important;
}
.getitbtns{
  color: black;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
}
.slick-dots.slick-dots-bottom {
  width: 100%;
}




.getitbtnboldtext{
  font-size: 16px;
  font-weight: 700;
}
.default-btn,
a {
  transition: 0.5s;
}
.mainlog{
margin-bottom: 80px;
margin-top: 40px;
}
.bolding{
  font-size: 24px;
  font-weight: bold;
  font-family: inter;
}
.block-open-close .title-open.active:after,
.faq-accordion .accordion .accordion__button.active i {
  transform: rotate(180deg);
}
.d-table-cell,
.flag-icon-banner {
  vertical-align: middle;
}
*,
.flag-icon-banner {
  box-sizing: border-box;
}
.active-tab,
.tab-button {
  align-self: center;
  outline: 0;
}
* {
  outline: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: Inter !important;
  overflow-x: hidden;
  overflow-y: auto;
  --wp--preset--color--black: #000000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #ffffff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #ffc32b;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(
    135deg,
    rgba(6, 147, 227, 1) 0%,
    rgb(155, 81, 224) 100%
  );
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(
    135deg,
    rgb(122, 220, 180) 0%,
    rgb(0, 208, 130) 100%
  );
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(
    135deg,
    rgba(252, 185, 0, 1) 0%,
    rgba(255, 105, 0, 1) 100%
  );
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(
    135deg,
    rgba(255, 105, 0, 1) 0%,
    rgb(207, 46, 46) 100%
  );
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(
    135deg,
    rgb(238, 238, 238) 0%,
    rgb(169, 184, 195) 100%
  );
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(
    135deg,
    rgb(74, 234, 220) 0%,
    rgb(151, 120, 209) 20%,
    rgb(207, 42, 186) 40%,
    rgb(238, 44, 130) 60%,
    rgb(251, 105, 98) 80%,
    rgb(254, 248, 76) 100%
  );
  --wp--preset--gradient--blush-light-purple: linear-gradient(
    135deg,
    rgb(255, 206, 236) 0%,
    rgb(152, 150, 240) 100%
  );
  --wp--preset--gradient--blush-bordeaux: linear-gradient(
    135deg,
    rgb(254, 205, 165) 0%,
    rgb(254, 45, 45) 50%,
    rgb(107, 0, 62) 100%
  );
  --wp--preset--gradient--luminous-dusk: linear-gradient(
    135deg,
    rgb(255, 203, 112) 0%,
    rgb(199, 81, 192) 50%,
    rgb(65, 88, 208) 100%
  );
  --wp--preset--gradient--pale-ocean: linear-gradient(
    135deg,
    rgb(255, 245, 203) 0%,
    rgb(182, 227, 212) 50%,
    rgb(51, 167, 181) 100%
  );
  --wp--preset--gradient--electric-grass: linear-gradient(
    135deg,
    rgb(202, 248, 128) 0%,
    rgb(113, 206, 126) 100%
  );
  --wp--preset--gradient--midnight: linear-gradient(
    135deg,
    rgb(2, 3, 129) 0%,
    rgb(40, 116, 252) 100%
  );
  --wp--preset--duotone--dark-grayscale: url("#wp-duotone-dark-grayscale");
  --wp--preset--duotone--grayscale: url("#wp-duotone-grayscale");
  --wp--preset--duotone--purple-yellow: url("#wp-duotone-purple-yellow");
  --wp--preset--duotone--blue-red: url("#wp-duotone-blue-red");
  --wp--preset--duotone--midnight: url("#wp-duotone-midnight");
  --wp--preset--duotone--magenta-yellow: url("#wp-duotone-magenta-yellow");
  --wp--preset--duotone--purple-green: url("#wp-duotone-purple-green");
  --wp--preset--duotone--blue-orange: url("#wp-duotone-blue-orange");
  --wp--preset--font-size--small: 13px;
  --wp--preset--font-size--medium: 20px;
  --wp--preset--font-size--large: 36px;
  --wp--preset--font-size--x-large: 42px;
  --wp--preset--font-family--albert-sans: "Albert Sans";
  --wp--preset--font-family--alegreya: Alegreya;
  --wp--preset--font-family--arvo: Arvo;
  --wp--preset--font-family--bodoni-moda: "Bodoni Moda";
  --wp--preset--font-family--cabin: Cabin;
  --wp--preset--font-family--chivo: Chivo;
  --wp--preset--font-family--commissioner: Commissioner;
  --wp--preset--font-family--cormorant: Cormorant;
  --wp--preset--font-family--courier-prime: "Courier Prime";
  --wp--preset--font-family--crimson-pro: "Crimson Pro";
  --wp--preset--font-family--dm-mono: "DM Mono";
  --wp--preset--font-family--dm-sans: "DM Sans";
  --wp--preset--font-family--domine: Domine;
  --wp--preset--font-family--eb-garamond: "EB Garamond";
  --wp--preset--font-family--epilogue: Epilogue;
  --wp--preset--font-family--figtree: Figtree;
  --wp--preset--font-family--fira-sans: "Fira Sans";
  --wp--preset--font-family--fraunces: Fraunces;
  --wp--preset--font-family--ibm-plex-mono: "IBM Plex Mono";
  --wp--preset--font-family--ibm-plex-sans: "IBM Plex Sans";
  --wp--preset--font-family--inter: Inter;
  --wp--preset--font-family--josefin-sans: "Josefin Sans";
  --wp--preset--font-family--jost: Jost;
  --wp--preset--font-family--libre-baskerville: "Libre Baskerville";
  --wp--preset--font-family--libre-franklin: "Libre Franklin";
  --wp--preset--font-family--literata: Literata;
  --wp--preset--font-family--lora: Lora;
  --wp--preset--font-family--merriweather: Merriweather;
  --wp--preset--font-family--montserrat: Montserrat;
  --wp--preset--font-family--newsreader: Newsreader;
  --wp--preset--font-family--nunito: "Plus Jakarta Sans";
  --wp--preset--font-family--open-sans: "Open Sans";
  --wp--preset--font-family--overpass: Overpass;
  --wp--preset--font-family--petrona: Petrona;
  --wp--preset--font-family--piazzolla: Piazzolla;
  --wp--preset--font-family--playfair-display: "Playfair Display";
  --wp--preset--font-family--plus-jakarta-sans: "Plus Jakarta Sans";
  --wp--preset--font-family--poppins: Poppins;
  --wp--preset--font-family--raleway: Raleway;
  --wp--preset--font-family--roboto-slab: "Roboto Slab";
  --wp--preset--font-family--roboto: Roboto;
  --wp--preset--font-family--rubik: Rubik;
  --wp--preset--font-family--sora: Sora;
  --wp--preset--font-family--source-sans-pro: "Source Sans Pro";
  --wp--preset--font-family--source-serif-pro: "Source Serif Pro";
  --wp--preset--font-family--space-mono: "Space Mono";
  --wp--preset--font-family--texturina: Texturina;
  --wp--preset--font-family--work-sans: "Work Sans";
  --wp--preset--spacing--20: 0.44rem;
  --wp--preset--spacing--30: 0.67rem;
  --wp--preset--spacing--40: 1rem;
  --wp--preset--spacing--50: 1.5rem;
  --wp--preset--spacing--60: 2.25rem;
  --wp--preset--spacing--70: 3.38rem;
  --wp--preset--spacing--80: 5.06rem;
  --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
  --wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
  --wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
  --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1),
    6px 6px rgba(0, 0, 0, 1);
  --wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);
}
img {
  max-width: 100%;
  height: auto;
}
ul li {
  list-style: none;
}

p {
  color: #606060;
  margin-bottom: 15px;
  line-height: 1.6;
}
.contact-info .line-icons-contact li,
.flex {
  display: flex;
}
.about-area-main .about-bottom .features-list,
.f-wrap {
  flex-wrap: wrap;
}
.f-direction-row {
  flex-direction: row;
}
.f-direction-col {
  flex-direction: column;
}
.justify-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.block-open-close,
.block-open-close li:last-child,
.courses-main .include-course ul li:last-child,
p:last-child {
  margin-bottom: 0;
}
:focus {
  outline: 0 !important;
}
.container {
  max-width: 1525px !important;
  padding: 0 15px;
  margin: auto;
}
.container-wider {
  max-width: 1552px;
  padding: 0 15px;
  margin: auto;
}
.container-full {
  max-width: 100%;
  padding: 0 31px;
  margin: auto;
}
.pt-100,
.ptb-100 {
  padding-top: 100px;
}
.pb-100,
.ptb-100 {
  padding-bottom: 100px;
}
.bg-fef8ef {
  background-color: #fef8ef;
}
.bg-fe4a55 {
  background-color: #ffc32b;
}
.bg-f5f1ed {
  background-color: #f5f1ed;
}
.bg-f0f2f5 {
  background-color: #f0f2f5;
}
.bg-f8f9f8 {
  background-color: #f8f9f8;
}
.d-table {
  width: 100%;
  height: 100%;
}
.bg-f7ebeb {
  background-color: #f7ebeb;
}
.bg-6dbbbd {
  background-color: #6dbbbd;
}
.font-weight-black {
  font-weight: 900 !important;
}
.bg-f9f9f9 {
  background-color: #f9f9f9;
}
.bg-6ba292,
.single-courses-box .courses-image .price.bg-45a393 {
  background-color: #6ba292;
}
.bg-f5f7fa {
  background-color: #f5f7fa;
}
.bg-fffaf3 {
  background-color: #fffaf3;
}
.bg-fff7f4 {
  background-color: #fff7f4;
}
.bg-f6f7fb {
  background-color: #f6f7fb;
}
.bg-fcf7f3 {
  background-color: #fcf7f3;
}
.bg-eee8df {
  background-color: #eee8df;
}
.pt-70,
.ptb-70 {
  padding-top: 70px;
}
.pb-70,
.ptb-70 {
  padding-bottom: 70px;
}
.pt-50 {
  padding-top: 50px;
}
.ptb-15,
textarea.form-control {
  padding-top: 15px;
  padding-bottom: 15px;
}
.mtb-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}
.fs-18,
.single-courses-box.style-2 .courses-content h3 {
  font-size: 18px;
}
.courses-details-header .courses-title p,
.fs-17 {
  font-size: 17px;
}
.fs-16 {
  font-size: 16px;
}
.fs-15 {
  font-size: 15px;
}
.fs-14 {
  font-size: 14px;
}
.fs-13 {
  font-size: 13px;
}
.fs-12 {
  font-size: 12px;
}
.mt-minus-2 {
  margin-top: -2px;
}
a {
  color: #221638;
  text-decoration: none;
}
a:hover {
  color: #000;
  text-decoration: none;
}
.playfair-display-font {
  font-family: "Playfair Display", serif;
}
.courses-sidebar-information .info li,
.error-content .btn-box a:first-child,
.form-group {
  margin-bottom: 20px;
}
.section-title {
  text-align: center;
  /* max-width: 720px; */
  margin-left: auto;
  margin-right: auto;
  /* margin-bottom: 72px; */
}
.section-title .sub-title {
  display: block;
  color: #ffc32b;
  line-height: 32px;
  letter-spacing: 1px;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 6px;
}
.section-title .tab-title,
.section-title h2 {
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 2px;
  color: #2c292a;
}
.section-title .tab-title {
  font-weight: 800;
}
.section-title .sub {
  color: #2c292a;
  font-size: 20px;
  display: block;
  font-weight: 300;
    max-width:886px;  
  margin: auto;
  letter-spacing: 1px;
  line-height: 32px;
}
.section-title h2 {
  font-weight: 800;
}
.courses-main .truck svg,
.img.flag img {
  display: block;
}
.default-btn {
  border: none;
  position: relative;
  display: block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: #2c292a;
  background-color: #ffc32b;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  padding: 16px 53px;
}
.default-btn:hover {
  background-color: #2c292a !important;
  color: #ffc32b !important;
}
.logo-nav {
  width: 100%;
  max-width: 264px;
}
.default-btn:disabled {
  cursor: not-allowed;
}
.about-area-main .about-bottom .features-list li:first-child,
.about-area-main .about-bottom .title-bottom,
.about-image.mobile,
.checkout-area .cart-side-left .list .item .mobile,
.content-details-course
  .content-infos
  .courses-details-desc-style-two
  > .details-title,
.content-details-course.mobile,
.course-mobile,
.courses-item .courses-block .mobile,
.courses-main .prices.mobile,
.default-btn:disabled span,
.login-form form .remember-me-wrap [type="checkbox"]:checked,
.login-form form .remember-me-wrap [type="checkbox"]:not(:checked),
.page-title-content ul li:last-child::before,
.pagination-area ._3bLAO ._2INQH,
.pagination-area ._3bLAO ._6ZFVN,
.privacy-policy-content .blockquote::after,
.privacy-policy-content .blockquote::before,
.privacy-policy-content blockquote::after,
.privacy-policy-content blockquote::before,
.sidebar-details.mobile,
.tab .tabs-item,
.terms-of-service-content .blockquote::after,
.terms-of-service-content .blockquote::before,
.terms-of-service-content blockquote::after,
.terms-of-service-content blockquote::before,
.video-content .pdf-viewer.mobile,
img#wpstats {
  display: none;
}
.default-btn-style-3,
.default-btn-style-two {
  display: inline-block;
  transition: 0.5s;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #fff;
  overflow: hidden;
}
.default-btn-style-3:hover span,
.default-btn:hover span {
  width: 225%;
  height: 562.5px;
}
.default-btn-style-two {
  border: 1px dashed #ffc32b;
  position: relative;
  z-index: 1;
  background-color: transparent;
  border-radius: 30px;
  padding: 15px 35px 15px 60px;
}
.default-btn-style-two i {
  position: absolute;
  left: 35px;
  top: 14px;
}
.default-btn-style-two::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ffc32b;
  z-index: -1;
  border-radius: 30px;
  margin: 4px;
  transition: 0.3s;
}
.contact-form form .invalid-feedback,
.default-btn-style-3:hover,
.default-btn-style-two:hover {
  color: #fff;
}
.contact-info .line-icons-contact li p,
.default-btn-style-two:hover::before,
.footer-bottom .right-block ul,
.instructor-header-content h4,
.message-bubble p,
body .is-layout-flex > * {
  margin: 0;
}
.default-btn-style-3 {
  border: none;
  position: relative;
  z-index: 1;
  background-color: #ffc32b;
  border-radius: 5px;
  padding: 15px 30px;
}
.default-btn-style-3 span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 5px;
  background-color: #221638;
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.form-control,
.form-select {
  height: 56px;
  color: #868686;
  box-shadow: unset !important;
  border: 2px solid rgba(44, 41, 42, 0.25);
  transition: 0.2s;
  border-radius: 8px;
  padding: 2px 0 0 16px;
  font-size: 16px;
  font-weight: 400;
}
.inputflex{
  display: flex;
  justify-content:space-between;
  align-items: center;
 padding-right: 10px;
}
.form-control::-moz-placeholder,
.form-select::-moz-placeholder {
  color: #7e7e7e;
  -moz-transition: 0.5s;
  transition: 0.5s;
}
.form-control::placeholder,
.form-select::placeholder {
  color: #7e7e7e;
  transition: 0.5s;
}
.form-control:focus,
.form-select:focus {
  border-color: #2c292a;
  background-color: transparent;
  transition: 0.2s ease-in;
}
.form-control:focus::-moz-placeholder,
.form-select:focus::-moz-placeholder {
  color: transparent;
}
.form-control:focus::placeholder,
.form-select:focus::placeholder {
  color: transparent;
}
textarea.form-control {
  height: auto;
}
.about-area-main {
  padding: 64px 0 122px;
  position: relative;
}
.about-area-main .about-content {
  max-width: 629px;
  position: relative;
  z-index: 1;
}
.about-area-main .about-image {
  position: absolute;
  top: 0;
  max-width: 1092px;
  right: 0;
  display: block;
}
.about-area-main .about-bottom .features-list li {
  width: calc(50% - 13px);
  margin: 0 26px 26px 0;
}
.about-area-main h3 {
  display: block;
  color: #2c292a;
  font-size: 24px;
  font-weight: 800;
  line-height: 28.8px;
  letter-spacing: 1.2px;
  margin-bottom: 31px;
}
.about-area-main .about-content p {
  color: #2c292a;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 31px;
  display: block;
  line-height: 32px;
  letter-spacing: 1px;
}
.about-area-main .about-bottom .features-list li:nth-child(odd) {
  margin-right: 0;
}

.about-area .about-area-block,
.about-area-content,
.advisor-area .content-instructor .social-link,
.basic-info .item-file,
.checkout-area .cart-side-left .content .info .list,
.checkout-area .checkout-area-content,
.contact-area .contact-area-content,
.courses-details-content,
.courses-details-desc-style-two .courses-author .author-profile,
.courses-item .courses-block .line-more-info,
.courses-main .courses-block .line-more-info,
.courses-meta .line-more-info,
.teacher-register-area .teacher-content,
.video-area .video-area-block,
.work-with-us-area .work-content {
  display: flex;
  flex-direction: row;
}

.about-area .about-area-block{
  justify-content: space-between;
  padding: 0 20px;
}
.courses-main .courses-block
{
  flex-direction: column !important;
}
.about-area {
  position: relative;
  z-index: 1;
}
.about-area .about-image {
  position: relative;
  overflow: hidden;
  width: 800px;
  border-radius: 16px;
height: 485px;
  margin-left: auto;
}
.about-area .about-image video {

  border-radius: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.about-area .about-content {
  width: calc(100% - 586px);
 max-width: 638px;
}
.about-content .sub-title {
  display: block;
  color: #ffc32b;
  margin-bottom: 10px;
  font-size: 30px;
  letter-spacing: 1px;
  line-height: 32px;
  font-weight: 700;
}
.about-content .sub,
.block-open-close .title-open + .hidden-result {
  font-weight: 300;
  line-height: 25.6px;
  font-size: 16px;
  letter-spacing: 0.8px;
  color: #2c292a;
}
.about-content h2 {
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  letter-spacing: 2px;
  color: #2c292a;
  margin-bottom: 16px;
}
.about-content .sub {
  margin-bottom: 40px;
}
.ref-open-title {
  font-family: "Archivo Black";
  color: #2c292a;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 1px;
  margin-bottom: 25px;
  line-height: 24px;
}
.block-open-close li {
  border-radius: 12px;
  background: #f1f1f1;
  padding: 20px 22px;
  margin-bottom: 16px;
  border: 2px solid #c8c8c8;
}
.block-open-close .title-open {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.8px;
  line-height: 25.6px;
  cursor: pointer;
  display: flex;
  position: relative;
}
.block-open-close .title-open:after {
  content: "";
  background: url('data:image/svg+xml,<svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="CaretDown"><path id="Vector" d="M21.0774 9L12.9707 16.5L4.86401 9" stroke="%232C292A" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/></g></svg>');
  width: 26px;
  height: 24px;
  position: absolute;
  top: 0;
  display: block;
  margin: auto;
  transition: 0.2s ease-in;
  right: 0;
}
.block-open-close .title-open .icon {
  margin-right: 5px;
}
.block-open-close .title-open + .hidden-result {
  margin-top: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  transition: 0.2s ease-in;
  text-align: left;
}
.block-open-close .title-open.active + .hidden-result {
  opacity: 1;
  visibility: visible;
  max-height: 100%;
  margin-top: 21px;
}
.about-area .about-bottom {
  margin-top: 64px;
}
.about-area .about-bottom .features-list,
.basic-info .button-save-bottom button,
.login-form form .lost-your-password-wrap a:hover::after {
  width: 100%;
}
.about-bottom .title-bottom {
  font-size: 24px;
  margin: auto;
}
.about-bottom .features-list li:first-child {
  max-width: 274px;
  background: 0 0;
}
.about-bottom .features-list li {
  width: calc(20% - 5px);
  margin: 0 13px;
  background: #ffecc3;
  border-radius: 12px;
}
.about-bottom .features-list li span {
  display: flex;
  height: 56px;
  justify-content: flex-start;
  margin: auto;
  line-height: 56px;
  font-size: 16px;
  text-align: center;
  padding: 17px 0 17px 14px;
}
.about-bottom .features-list li span .icon,
.courses-main .include-course ul li .icon {
  margin-right: 6px;
}
.about-content .default-btn {
  margin-top: 30px;
}
.about-img {
  position: relative;
  z-index: 1;
}
.about-img .image {
  text-align: right;
  position: relative;
  padding-bottom: 45px;
}
.about-img .image img:nth-child(2) {
  position: absolute;
  left: 80px;
  bottom: 0;
}
@media (max-width: 1700px) {
  .about-area-main .about-image {
    max-width: 980px;
  }
}
@media (max-width: 1450px) {
  .about-area-main .about-image {
    max-width: 800px;
  }
}
@media (max-width: 1250px) {
  .about-area-main .about-image {
    max-width: 700px;
  }
}
@media (max-width: 1200px) {
  .about-area-main {
    padding-top: 0;
  }
  .about-area-main .about-content {
    padding-top: 64px;
  }
  .about-area-main .about-content:after {
    position: absolute;
    top: -25px;
    right: -20px;
    content: "";
    bottom: 0;
    display: block;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    background: #fff;
    z-index: -1;
    filter: blur(8px);
  }
}
@media (max-width: 1270px) {
  .about-area .about-image {
    height: 600px;
  }
  .about-area .about-content {
    width: calc(100% - 460px);
  }
  .feedback-area .left-block {
    padding-right: 40px;
  }
}
@media (max-width: 1140px) {
  .about-bottom .features-list li:first-child {
    width: 146px;
  }
  .about-area.about-image {
    width: 400px;
  }
  .about-area .about-content {
    width: calc(100% - 400px);
  }
  .about-area .about-bottom .features-list li span {
    padding: 17px 0 17px 8px;
  }
  .about-area .about-bottom .features-list li {
    margin: 0 6px;
  }
}
@media (max-width: 990px) {
  .container,
  .container-wider {
    padding: 0 33px;
  }
  .left-hero-block{
    margin: auto;
  }
  .callphone-block{
    height: auto !important;
  }
 
  .section-title .sub {
    margin: 0;
  }
  .about-area-main .about-content:after {
    background: 0 0;
    display: none;
  }
  .about-area-main .about-content {
    padding-top: 0;
  }
  .about-area-main {
    padding: 64px 0;
  }
  .about-area-main .about-image,
  .about-image.desktop {
    display: none;
  }
  .about-area {
    padding: 78px 0;
  }
  .about-area .about-content h2 {
    max-width: initial;
  }
  .about-area .about-bottom .features-list {
    flex-direction: column;
  }
  

  .about-image.mobile {
    display: block;
    width: 500px;
    margin: auto auto 40px;
    height: 700px;
  }
  .about-area .about-content {
    width: 100%;
    padding: 0;
    padding-left: 15px;
    padding-right: 10px;
    text-align: left;
  }
  .about-area .about-bottom .features-list {
    max-width: 500px;
    margin: auto;
  }
  .about-area .about-bottom .features-list li:first-child {
    width: 100%;
    margin: 0 0 24px;
    text-align: left;
    padding: 0;
    max-width: 100%;
  }
  .about-area .about-bottom .features-list li {
    width: 100%;
    margin-bottom: 16px;
    padding: 11px 14px;
  }
  .about-area .about-bottom .features-list li:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .section-title .sub-title {
    font-size: 20px;
    line-height: 28.8px;
    display: block;
  }
 
  .subscribe-content .newsletter-form .input-newsletter{
    border: 2px solid #f4f4f4 !important;
    margin-bottom: 0px !important;
  }
  .subscribe-content .newsletter-form{
    border: none !important;
    flex-direction: column;
  }
  .section-title .tab-title,
  .section-title h2 {
    font-size: 24px;
    line-height: 28.8px;
  }
  .section-title .sub {
    line-height: 22.8px;
    font-size: 16px;
  }
  .cards{
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    justify-content: center;
  }
  .about-area {
    padding: 64px 0;
  }
  .about-area .about-content{
    padding: 0px;
  }
  .about-area{
    padding-left: 0px !important;
    padding-right: 0px !important;

  }
  .about-image.mobile {
    width: auto;
    height: 800px;
  }
  .about-area-main .about-content p {
    font-size: 16px;
  }
  .about-content h2 {
    font-size: 24px;
    line-height: 28.8px;
    margin-bottom: 16px;
  }
  .about-area .about-content .sub-title {
    font-size: 20px;
    line-height: 28.8px;
  }
  .vousebtn{
    display: block !important;
  }
  .about-area .about-content .sub,
  .about-area-main .about-content .sub {
    line-height: 160%;
    font-size: 17px;
  }
}
@media (max-width: 530px) {
  .about-area-main .about-bottom .features-list {
    flex-direction: column;
  }
  .about-area-main .about-content p {
    margin-bottom: 64px;
  }
  .about-area-main .about-bottom .features-list li {
    width: 100%;
    margin: 0 0 16px;
  }
  .about-area-main .about-bottom .features-list li:last-child {
    margin-bottom: 0;
  }
}
.apply-instructor-content h3:not(:first-child),
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  h3:not(:first-child),
.courses-details-desc
  .react-tabs__tab-panel
  .courses-overview
  h3:not(:first-child),
.courses-details-desc-style-two .courses-curriculum h3:not(:first-child) {
  margin-top: 30px;
}
@media (max-width: 500px) {
  .block-open-close .title-open:after {
    right: -10px;
    bottom: 0;
    background-color: #f1f1f1;
  }
  .mainlog{
    margin-bottom: 30px;
  }
  .block-open-close li {
    padding: 9px 18px 9px 13px;
  }
  .about-image.mobile {
    width: auto;
    height: 600px;
  }
}
@media (max-width: 450px) {
  .about-image.mobile {
    width: auto;
    height: 500px;
  }
}
@media (max-width: 390px) {
  .about-image.mobile {
    width: auto;
    height: 400px;
  }
}
@keyframes movebounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
}
@keyframes moveleftbounce {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
}
@keyframes rotateme {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes movescale {
  0%,
  100% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(0.8);
  }
}
.about-content .about-list {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: -5px -8px 0;
}
.about-content .about-list li {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 16px;
}
.about-content .about-list li span {
  display: block;
  position: relative;
  font-weight: 700;
  padding-left: 40px;
  padding-top: 3px;
}
.about-content .about-list li span i {
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  background-color: #a3c5bb;
  z-index: 1;
  border-radius: 50%;
  font-size: 20px;
  transition: 0.5s;
}
.about-content .about-list li span i::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #6ba292;
  border-radius: 50%;
  z-index: -1;
  margin: 2px 1px 1px 2px;
  transition: 0.5s;
}
.about-content .about-list li span:hover i {
  background-color: #ffc32b;
  color: #ffc32b;
}
.about-content .about-list li span:hover i::after {
  background-color: #fff;
}
.about-content .default-btn-style-two,
.pagination-area {
  margin-top: 35px;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 20px;
}
.courses-area {
  position: relative;
  z-index: 1;
  background: #fff;
}
.courses-main .courses-block {
  border: 2px solid #e8e8e8;
  border-radius: 8px;
  padding: 46px 30px;
  padding-top: 70px;
  position: relative;
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: space-between;
}
.courses-main .courses-block .title-block {
  font-size: 24px;
  color: #2c292a;
  font-weight: 700;
  line-height: 28.8px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.courses-main .courses-block .title-block img {
  margin-left: 33px;
}
.courses-item .courses-block .info,
.courses-main .courses-block .info {
  max-width: 476px;
  margin: 16px 0 40px;
  font-weight: 300;
  line-height: 25.6px;
  font-size: 16px;
  letter-spacing: 0.8px;
}
.courses-item .courses-block .info {
  margin: 18px 0 17px;
}
.courses-main .courses-block .left-block,
.courses-main .include-course {
  padding-right: 15px;
}
.courses-main .include-course ul li {
  display: flex;
  color: #2c292a;
  font-size: 16px;
  font-weight: 300;
  line-height: 25.6px;
  letter-spacing: 0.8px;
  margin-bottom: 20px;
}
.courses-area-list .courses-item .image-content,
.courses-block .promo-block,
.courses-item .title-block,
.get-certificate .certificate-img {
  position: relative;
}
.courses-item .action-dropdown {
  position: initial;
  top: 0;
  right: 0;
}
.courses-item .action-dropdown .icon {
  border-radius: 50%;
}
.courses-item .courses-block .price-line {
  display: flex;
  flex-direction: row;
  margin: 0 0 30px;
  line-height: 19.2px;
  justify-content: space-between;
  font-size: 18px;
  color: #2c292a;
  font-weight: 700;
}
.courses-item .courses-block .price-line .price-bought {
  margin-right: 12px;
}
.checkout-area .checkout-area-content .empty-full .icon,
.courses-item .courses-block .line-more-info {
  margin-bottom: 24px;
}
.courses-main .courses-block .buy-now {
  max-width: 277px;
}
.courses-main .prices h1 {
  color: #2c292a;
  font-size: 56px;
  line-height: 67.2px;
  letter-spacing: 2.8px;
  font-weight: 900;
  margin-bottom: 0;
}
.courses-main .prices h1::first-letter {
  font-size: 24px;
}
.courses-item .courses-block .buy-now strong,
.courses-main .courses-block .buy-now strong {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.courses-main .courses-block .buy-now input {
  padding: 0 16px;
  border: 2px solid #d8d4d5;
  color: #6f6b70;
  height: 56px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 24px;
}
.courses-block .apply-cupom {
  background: 0 0;
  color: #2c292a;
  height: 56px;
  border: none;
  position: absolute;
  font-size: 14px;
  transition: 0.2s ease-in;
  right: 10px;
}
.courses-block .apply-cupom:hover,
.register-firstTime {
  color: #fbb723;
}
.courses-block .buy-now a.link-buy {
  border-radius: 8px;
  background: #fbb723;
  width: 100%;
  height: 56px;
  line-height: 56px;
  color: #2c292a;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  border: none;
  transition: 0.2s ease-in;
  display: block;
}
.courses-block .buy-now a.link-buy:hover,
.footer-content .email-bottom .social-link li a:hover {
  color: #fbb723;
  background: #2c292a;
}
.courses-item .courses-block .buy-now .grade-link,
.courses-main .courses-block .buy-now .grade-link {
  font-size: 14px;
  color: #2c292a;
  text-decoration: underline;
  letter-spacing: 0.28px;
  line-height: 24px;
  display: block;
  font-weight: 500;
  text-align: center;
  margin-top: 16px;
  cursor: pointer;
}
.courses-item .courses-block .line-more-info .item,
.courses-main .courses-block .line-more-info .item,
.courses-meta .line-more-info .item {
  padding: 0 24px;
  border-right: 2px solid rgba(44, 41, 42, 0.15);
}
.courses-item .courses-block .line-more-info .item:first-child,
.courses-main .courses-block .line-more-info .item:first-child,
.courses-meta .line-more-info .item:first-child,
.disclaimer .line-numbers .block-number:first-child {
  padding-left: 0;
}
.courses-item .courses-block .line-more-info .item.not-border-right,
.courses-item .courses-block .line-more-info .item:last-child,
.courses-main .courses-block .line-more-info .item:last-child,
.courses-meta .line-more-info .item:last-child,
.disclaimer .line-numbers .block-number:last-child {
  padding-right: 0;
  border-right: none;
}
.courses-item .courses-block .line-more-info span,
.courses-main .courses-block .line-more-info span,
.courses-meta .line-more-info span {
  display: block;
  font-size: 14px;
  color: #2c292a;
}
.courses-meta .line-more-info span {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 1px;
  font-weight: 300;
}
.courses-meta .line-more-info a {
  letter-spacing: 1px;
}
.courses-item .courses-block .line-more-info strong,
.courses-main .courses-block .line-more-info strong,
.courses-meta .line-more-info a {
  line-height: 19.2px;
  font-size: 16px;
  color: #2c292a;
  font-weight: 700;
}
.courses-main .truck {
  position: absolute;
  top: 11px;
  left: 17px;
  background: #fff;
  width: 54px;
  z-index: 2;
  height: 54px;
  border: 1px solid #f1f1f1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.courses-info p a,
.single-courses-box .courses-image .fav:hover {
  color: #ffc32b;
}
.single-courses-box .courses-image .price {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  background: #ffc32b;
  box-shadow: 0 0 8.678719520568848px rgba(0, 0, 0, 0.25);
  border-radius: 12.15px;
  color: #000;
  width: auto;
  min-width: 30% !important;
  max-width: 40% !important;
  height: auto;
  min-height: max-content !important;
  position: absolute;
  left: 10px;
  bottom: -32.5px;
  text-align: center;
}
.single-courses-box.bg-color:hover,
.single-courses-box.without-boxshadow.active,
.single-courses-box.without-boxshadow:hover {
  box-shadow: 0 8px 16px 0 rgba(146, 184, 255, 0.2);
}
.single-courses-box .courses-image .discount-price {
  right: 100px;
  background: #ffc32b;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  line-height: 75px;
  width: 75px;
  height: 75px;
}
.single-courses-box .courses-content {
  border-radius: 0 0 5px 5px;
  padding: 30px;
}
.courses-info .default-btn,
.single-courses-box .courses-content .course-author {
  margin-bottom: 15px;
}
.single-courses-box .courses-content .course-author img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}
.single-courses-box .courses-content .course-author span {
  color: #ffc32b;
  font-weight: 600;
  font-size: 15px;
}
.single-courses-box .courses-content h3 {
  margin-bottom: 12px;
  line-height: 1.3;
  font-size: 24px;
}
.single-courses-box .courses-content h3 a {
  display: inline-block;
}
.single-courses-box .courses-content .courses-box-footer {
  list-style-type: none;
  padding-left: 0;
  margin: 20px -7px 0;
}
.single-courses-box .courses-content .courses-box-footer li {
  color: #606060;
  font-size: 15px;
  position: relative;
  padding-left: 25px;
  padding-right: 7px;
}
.single-courses-box .courses-content .courses-box-footer li i {
  position: static;
  left: 0;
  top: 0;
  font-size: 20px;
  font-weight: 600;
  color: #97700c;
  transition: 0.3s;
  text-align: center;
  align-items: center;
  align-content: center;
  align-self: center;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  order: 0;
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: 0 0;
  font: inherit;
  vertical-align: baseline;
  text-transform: capitalize;
  text-decoration: none;
}
.single-courses-box .courses-content .courses-box-footer li i.flaticon-agenda,
.single-courses-box .courses-content .courses-box-footer li i.flaticon-people {
  font-size: 22px;
  left: 3px;
}
.single-courses-box.bg-color {
  background-color: #fffaf3;
  box-shadow: unset;
  transition: 0.5s;
}
.single-courses-box.bg-color .courses-content h3,
.single-courses-box.without-boxshadow .courses-content h3 {
  margin-bottom: 10px;
}
.single-courses-box.bg-color:hover {
  background-color: #fff;
}
.single-courses-box.without-boxshadow {
  background-color: #fff;
  box-shadow: unset;
  transition: 0.5s;
}
.single-courses-box.style-2 .video_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.4);
  transform: scale(0);
  transition: 0.5s;
}
.single-courses-box.style-2 .video_box i {
  background-color: #fff;
  width: 60px;
  height: 60px;
  line-height: 60px;
  color: #000;
  font-size: 50px;
  border-radius: 80px;
  padding-left: 5px;
  cursor: pointer;
}
.courses-details-info .courses-share .share-info .social-link li a:hover,
.courses-sidebar-information .courses-share .share-info .social-link li a:hover,
.single-courses-box.style-2 .video_box i:hover {
  background-color: #ffc32b !important;
  color: #f0f2f4;
}
.single-courses-box.style-2:hover .video_box {
  transform: scale(1);
}
.single-courses-box.style-2 .courses-content {
  padding: 20px;
}
.courses-info {
  text-align: center;
  margin-top: 20px;
}
.courses-info p {
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}
.courses-info p a:hover,
.feedback-content .feedback-info p a:hover {
  text-decoration: underline;
  color: #221638;
}
.courses-area.bg-image {
  background-color: #fff7f4;
  /* background-image: url(/images/courses-bg.jpg); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.courses-details-desc {
  margin-top: 60px;
  padding-right: 15px;
}
.courses-details-desc .react-tabs__tab-list {
  margin-bottom: 0;
  list-style-type: none;
  background-color: #f8f9f8;
  border: none;
  border-radius: 5px;
  padding-left: 30px;
  padding-right: 30px;
}
.courses-details-desc .react-tabs__tab-list .react-tabs__tab {
  margin-bottom: 0;
  margin-right: 40px;
  border-radius: 5px;
  border: none;
  padding: 14px 0;
  transition: 0.5s;
  background-color: transparent;
  position: relative;
  font-size: 18px;
  font-weight: 800;
}
.courses-details-desc .react-tabs__tab-list .react-tabs__tab::before {
  content: "";
  bottom: 1px;
  height: 2px;
  background-color: #ffc32b;
  position: absolute;
  transition: 0.5s;
  left: 0;
  width: 100%;
  transform: scaleX(0);
}
.courses-details-desc
  .react-tabs__tab-list
  .react-tabs__tab.react-tabs__tab--selected,
.courses-details-desc .react-tabs__tab-list .react-tabs__tab:hover,
.courses-details-desc-style-two .audience-list li strong,
.courses-details-desc-style-two .description-features-list li strong,
.courses-details-desc-style-two p strong,
.courses-details-info .courses-share .share-info span i,
.courses-sidebar-information .courses-share .share-info span i {
  color: #221638;
}
.courses-details-desc
  .react-tabs__tab-list
  .react-tabs__tab.react-tabs__tab--selected::before,
.courses-details-desc .react-tabs__tab-list .react-tabs__tab:hover::before {
  transform: scaleX(1);
}
.advisor-area .content-instructor .single-advisor-box:last-child,
.courses-details-desc .react-tabs__tab-list .react-tabs__tab:last-child,
.courses-details-info .courses-share .share-info .social-link li:last-child,
.courses-sidebar-information
  .courses-share
  .share-info
  .social-link
  li:last-child {
  margin-right: 0;
}
.courses-details-desc .react-tabs__tab-panel {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 40px;
}
.courses-details-desc .react-tabs__tab-panel .courses-overview h3 {
  margin-bottom: 12px;
  font-size: 24px;
}
.courses-details-desc .react-tabs__tab-panel .courses-curriculum {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 30px;
  margin-left: -30px;
  margin-right: -30px;
}
.courses-details-desc .react-tabs__tab-panel .courses-curriculum h3 {
  margin-bottom: 18px;
  font-size: 20px;
}
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul,
.courses-details-desc-style-two .courses-curriculum ul,
.courses-details-header .courses-meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.main-title-hero{
  max-width: 807px;
  min-width: 589px;
  display: inline-block;
}
.basic-info .label-file,
.checkout-area .cart-side-left .content .info .list span,
.checkout-area .cart-side-left .content .info .list strong,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li,
.tab .tabs-item:first-child {
  display: block;
}
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li a {
  background-color: #f8f9f8;
  color: #221638;
  position: relative;
  padding: 14.5px 30px 14.5px 55px;
  margin-left: -30px;
  margin-right: -30px;
}
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li
  a::before {
  content: "\f104";
  position: absolute;
  left: 30px;
  color: #ffc32b;
  top: 14px;
  font-size: 18px;
  font-family: Flaticon;
}
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li
  a
  .courses-name {
  font-size: 14.5px;
  font-weight: 700;
}
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li
  a
  .courses-meta,
.courses-details-desc .react-tabs__tab-panel .courses-reviews .right,
.courses-details-desc-style-two .courses-reviews .right,
.courses-details-header .courses-price,
.login-form form .lost-your-password-wrap {
  text-align: right;
}
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li
  a
  .courses-meta
  .questions,
.courses-details-desc-style-two
  .courses-curriculum
  ul
  li
  .courses-meta
  .questions {
  display: inline-block;
  background: #e3f1f2;
  color: #2dbbc4;
  text-transform: lowercase;
  border-radius: 3px;
  margin-right: 6px;
  padding: 2px 10px 1.5px;
  font-size: 14px;
  font-weight: 700;
}
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li
  a
  .courses-meta
  .duration {
  display: inline-block;
  background: #f7e7e8;
  color: #ffc32b;
  text-transform: lowercase;
  border-radius: 3px;
  padding: 2px 10px 1.5px;
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
}
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li
  a
  .courses-meta
  .status {
  display: inline-block;
  background: #221638;
  color: #fff;
  border-radius: 3px;
  margin-left: 6px;
  padding: 2px 10px 1.5px;
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
}
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li
  a
  .courses-meta
  .status.locked,
.courses-details-desc-style-two
  .courses-curriculum
  ul
  li
  .courses-meta
  .status.locked {
  color: #221638;
  background-color: transparent;
  padding: 0;
  margin-left: 8px;
  border-radius: 0;
  position: relative;
  top: 1px;
  font-size: 18px;
  font-weight: 400;
}
.contact-form form .default-btn:hover,
.courses-details-desc .react-tabs__tab-panel .courses-curriculum ul li a:hover,
.courses-details-desc-style-two .courses-curriculum ul li:hover,
.courses-details-header .courses-meta ul li a:focus,
.courses-details-header .courses-meta ul li a:hover,
.page-title-content ul li a:hover {
  color: #ffc32b;
}
.contact-form form .default-btn span,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(10)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(100)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(12)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(14)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(16)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(18)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(2)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(20)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(22)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(24)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(26)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(28)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(30)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(32)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(34)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(36)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(38)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(4)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(40)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(42)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(44)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(46)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(48)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(50)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(52)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(54)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(56)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(58)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(6)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(60)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(62)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(64)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(66)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(68)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(70)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(72)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(74)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(76)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(78)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(8)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(80)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(82)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(84)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(86)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(88)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(90)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(92)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(94)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(96)
  a,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-curriculum
  ul
  li:nth-child(98)
  a {
  background-color: #fff;
}
.courses-details-desc
  .react-tabs__tab-panel
  .courses-instructor
  .single-advisor-box,
.courses-details-desc-style-two .audience-list li:last-child,
.courses-details-desc-style-two .description-features-list li:last-child,
.courses-details-desc-style-two .requirements-list li:last-child {
  margin-bottom: 0;
}
.courses-details-desc
  .react-tabs__tab-panel
  .courses-instructor
  .single-advisor-box
  .advisor-image {
  top: 0;
  margin-bottom: 0;
}
.courses-details-desc
  .react-tabs__tab-panel
  .courses-instructor
  .single-advisor-box
  .advisor-content {
  padding: 0 0 0 20px;
}
.courses-details-desc .react-tabs__tab-panel .courses-reviews h3 {
  margin-bottom: 0;
  display: inline-block;
  margin-right: 15px;
  font-size: 21px;
}
.single-funfacts img,
.testimonials-slides,
.video-box {
  margin-right: auto;
  margin-left: auto;
}
.courses-details-desc .react-tabs__tab-panel .courses-reviews .rating {
  display: inline-block;
  position: relative;
  top: 1px;
}
.courses-details-desc .react-tabs__tab-panel .courses-reviews .rating span {
  font-size: 19px;
  color: #cecfd2;
  margin-right: 2px;
}
.courses-details-desc
  .react-tabs__tab-panel
  .courses-reviews
  .rating
  span.checked {
  color: orange;
}
.courses-details-desc .react-tabs__tab-panel .courses-reviews .rating-count,
.courses-details-desc-style-two .courses-reviews .rating-count {
  margin-top: 10px;
  margin-bottom: 20px;
}
.courses-details-desc
  .react-tabs__tab-panel
  .courses-reviews
  .rating-count
  span,
.courses-details-desc-style-two .courses-reviews .rating-count span {
  display: block;
  font-size: 16px;
  color: #606060;
}
.courses-details-desc .react-tabs__tab-panel .courses-reviews .row,
.courses-details-desc-style-two .courses-reviews .row {
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.courses-details-desc .react-tabs__tab-panel .courses-reviews .side,
.courses-details-desc-style-two .courses-reviews .side {
  float: left;
  width: 9%;
  margin-top: 10px;
}
.courses-details-desc .react-tabs__tab-panel .courses-reviews .side div,
.courses-details-desc-style-two .courses-reviews .side div {
  font-size: 16px;
  font-weight: 700;
}
.courses-details-desc .react-tabs__tab-panel .courses-reviews .middle,
.courses-details-desc-style-two .courses-reviews .middle {
  margin-top: 14px;
  float: left;
  width: 82%;
}
.courses-details-desc .react-tabs__tab-panel .courses-reviews .bar-container,
.courses-details-desc-style-two .courses-reviews .bar-container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: #fff;
  border-radius: 5px;
}
.courses-details-desc .react-tabs__tab-panel .courses-reviews .bar-5,
.courses-details-desc-style-two .courses-reviews .bar-5 {
  width: 100%;
  height: 18px;
  background-color: #4caf50;
  border-radius: 5px;
}
.courses-details-desc .react-tabs__tab-panel .courses-reviews .bar-4,
.courses-details-desc-style-two .courses-reviews .bar-4 {
  width: 75%;
  height: 18px;
  background-color: #2196f3;
  border-radius: 5px;
}
.courses-details-desc .react-tabs__tab-panel .courses-reviews .bar-3,
.courses-details-desc-style-two .courses-reviews .bar-3 {
  width: 50%;
  height: 18px;
  background-color: #00bcd4;
  border-radius: 5px;
}
.courses-details-desc .react-tabs__tab-panel .courses-reviews .bar-2,
.courses-details-desc-style-two .courses-reviews .bar-2 {
  width: 25%;
  height: 18px;
  background-color: #ff9800;
  border-radius: 5px;
}
.courses-details-desc .react-tabs__tab-panel .courses-reviews .bar-1,
.courses-details-desc-style-two .courses-reviews .bar-1 {
  width: 0;
  height: 18px;
  background-color: #f44336;
  border-radius: 5px;
}
.courses-details-desc .react-tabs__tab-panel .courses-review-comments,
.courses-review-comments,
.related-courses,
.video-area .right-side .courses-details-desc-style-two {
  margin-top: 40px;
}
.courses-details-desc .react-tabs__tab-panel .courses-review-comments h3 {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 12px;
  margin-bottom: 8px;
  font-size: 21px;
}
.courses-details-desc
  .react-tabs__tab-panel
  .courses-review-comments
  .user-review {
  border-bottom: 1px solid #f3f3f3;
  padding: 20px 0 20px 110px;
  position: relative;
}
.courses-details-desc
  .react-tabs__tab-panel
  .courses-review-comments
  .user-review
  img {
  position: absolute;
  left: 0;
  top: 20px;
  width: 90px;
  height: 90px;
  border-radius: 5px;
}
.courses-details-desc
  .react-tabs__tab-panel
  .courses-review-comments
  .user-review
  .sub-comment {
  margin-bottom: 8px;
  font-weight: 700;
}
.basic-info .label-file > span,
.courses-details-desc
  .react-tabs__tab-panel
  .courses-review-comments
  .user-review
  .review-rating,
.courses-review-comments .user-review .review-rating {
  display: block;
  margin-bottom: 8px;
}
.contact-row .item label span{
  display: flex;
  margin-bottom: 10px;
}
.courses-details-desc
  .react-tabs__tab-panel
  .courses-review-comments
  .user-review
  .review-rating
  .review-stars,
.courses-details-header .courses-price .courses-review .review-stars,
.courses-review-comments .user-review .review-rating .review-stars {
  display: inline-block;
}
.courses-details-desc
  .react-tabs__tab-panel
  .courses-review-comments
  .user-review
  .review-rating
  .review-stars
  i {
  color: #cecfd2;
  font-size: 18px;
  display: inline-block;
  margin-right: 2px;
}
.courses-details-desc
  .react-tabs__tab-panel
  .courses-review-comments
  .user-review
  .review-rating
  .review-stars
  i.checked,
.courses-details-desc-style-two .courses-reviews .rating span.checked,
.courses-review-comments .user-review .review-rating .review-stars i.checked {
  color: orange;
}
.courses-details-desc
  .react-tabs__tab-panel
  .courses-review-comments
  .user-review
  .review-rating
  span {
  color: #221638;
  position: relative;
  top: -2px;
  font-weight: 700;
  margin-left: 5px;
}
.courses-details-info {
  background-color: #f8f9f8;
  border-radius: 5px;
  padding: 12px 12px 50px;
  margin-left: 15px;
  margin-top: -248px;
}
.courses-details-info .image {
  text-align: center;
  position: relative;
}
.courses-details-info .image .link-btn {
  display: block;
  width: 100%;
  height: 100%;
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
}
.courses-details-info .image .content {
  position: absolute;
  left: 0;
  z-index: 2;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.courses-details-info .image .content i {
  display: block;
  width: 60px;
  height: 60px;
  background-color: #ffc32b;
  color: #fff;
  border-radius: 50%;
  line-height: 60px;
  font-size: 25px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}
.courses-details-info .image .content span {
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  padding: 4px 20px;
  background-color: #221638;
  font-size: 17px;
  font-weight: 700;
}
.courses-details-info .image::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: #221638;
  opacity: 0.25;
}
.courses-details-info .info {
  margin-bottom: 0;
  list-style-type: none;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 35px;
}
.courses-details-info .info li {
  border-bottom: 1px solid #e5e5e7;
  color: #606060;
  font-size: 17px;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 14px;
}
.courses-details-info .info li span {
  font-weight: 800;
  color: #221638;
  position: relative;
  padding-left: 28px;
}
.courses-details-info .info li span i,
.courses-sidebar-information .info li span i {
  color: #ffc32b;
  position: absolute;
  left: 0;
  font-weight: 400;
  top: -2px;
  font-size: 20px;
}
.courses-details-info .info li.price,
.courses-sidebar-information .info li.price {
  padding-bottom: 10px;
  color: #ffc32b;
  font-size: 28px;
  font-weight: 800;
}
.courses-details-info .info li.price span,
.courses-sidebar-information .info li.price span {
  color: #221638;
  font-size: 17px;
  font-weight: 800;
}
.courses-details-info .info li:first-child,
.courses-sidebar-information .info li:first-child {
  padding-top: 0;
}
.courses-details-info .btn-box {
  margin-top: 35px;
  padding-left: 25px;
  padding-right: 25px;
}
.courses-details-info .btn-box .default-btn {
  display: block;
  padding: 12px 30px;
}
.courses-details-info .btn-box .default-btn i,
.courses-sidebar-information .btn-box .default-btn i {
  position: relative;
  left: 0;
  top: 2px;
  margin-right: 8px;
  line-height: 1;
  font-weight: 400;
  font-size: 20px;
}
.courses-details-info .btn-box .default-btn:not(:first-child),
.courses-sidebar-information .btn-box .default-btn:not(:first-child) {
  margin-top: 15px;
  background-color: transparent;
  color: #221638;
  border: 1px solid #221638;
}
.courses-details-info .btn-box .default-btn:not(:first-child):hover,
.courses-sidebar-information .btn-box .default-btn:not(:first-child):hover {
  color: #fff;
  border-color: #221638;
}
.courses-details-info .courses-share {
  text-align: center;
  margin-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
}
.courses-details-info .courses-share .share-info {
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.courses-details-info .courses-share .share-info span,
.courses-sidebar-information .courses-share .share-info span {
  display: inline-block;
  color: #ffc32b;
  line-height: 1;
  font-size: 16px;
  font-weight: 700;
}
.courses-details-info .courses-share .share-info .social-link {
  padding-left: 0;
  list-style-type: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -35px;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  margin-bottom: 0;
}
.courses-details-info .courses-share .share-info .social-link li,
.courses-sidebar-information .courses-share .share-info .social-link li {
  display: inline-block;
  margin-right: 3px;
}
.courses-details-info .courses-share .share-info .social-link li a,
.courses-sidebar-information .courses-share .share-info .social-link li a {
  width: 30px;
  height: 30px;
  text-align: center;
  background-color: #eef106;
  font-size: 18px;
  color: #221638;
  position: relative;
  border-radius: 2px;
}
.courses-details-info .courses-share .share-info .social-link li a i,
.courses-sidebar-information .courses-share .share-info .social-link li a i {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-48%);
}
.courses-details-info .courses-share .share-info:hover .social-link {
  opacity: 1;
  visibility: visible;
  bottom: -42px;
}
.basic-profile-information-form,
.contact-area .contact-area-content,
.courses-details-area {
  padding: 64px 0 120px;
}
.courses-details-header .courses-meta,
.courses-sidebar-information .btn-box,
.privacy-policy-content ol,
.privacy-policy-content ul,
.quiz-container ul,
.terms-of-service-content ol,
.terms-of-service-content ul {
  margin-top: 20px;
}
.courses-details-header .courses-meta ul li {
  margin-right: 20px;
  position: relative;
  display: inline-block;
  border-right: 1px solid #eee;
  font-weight: 800;
  font-size: 17px;
  padding-right: 20px;
  padding-left: 40px;
}
.courses-details-header .courses-meta ul li i {
  font-size: 28px;
  color: #cfcfcf;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.courses-details-header .courses-meta ul li span {
  display: block;
  color: #ffc32b;
  text-transform: uppercase;
  margin-bottom: 1px;
  font-size: 13.5px;
  font-weight: 700;
}
.courses-details-header .courses-meta ul li a {
  display: inline-block;
  color: #221638;
}
.courses-details-header .courses-meta ul li:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: none;
}
.homereview{
  max-width: 1500px;
  margin: auto;
}
.courses-details-header .courses-price .price {
  display: inline-block;
  margin-right: 15px;
  line-height: 1;
  position: relative;
  top: 8px;
  font-size: 36px;
  font-weight: 800;
}
.courses-details-header .courses-price .courses-review {
  display: block;
  margin-bottom: 10px;
}
.courses-details-header .courses-price .courses-review .review-stars i {
  color: #f2b827;
  font-size: 18px;
  display: inline-block;
  margin-right: 2px;
}
.posted{
  font-size: 15px;
  font-family: Inter;
  color: #757273;
  display: flex;
  align-items: center;
  width: 100%;
margin-top: 10px;
  justify-content: center;
  gap: 8px;
}
.about-image .animation-container{
  top: 42%;
  left: 46%;
}
.about-image .animation-container .learntext{
  right: 17px
}
.courses-details-header .courses-price .courses-review .reviews-total {
  color: #606060;
  position: relative;
  top: -1px;
  margin-left: 2px;
  font-weight: 700;
}
.courses-details-image-style-two {
  border-radius: 16px;
  max-height: 445px;
  overflow: hidden;
}
.courses-details-image-style-two img {
  width: 100%;
  border-radius: 16px;
  height: 100%;
}
.empty-assets {
  text-align: center;
  margin-top: -37px;
}
.content-details-course .courses-details-desc-style-two:first-child {
  margin-top: 37px;
}
.content-details-course .courses-details-desc-style-two {
  margin-top: 26px;
}
.courses-details-desc-style-two {
  margin-top: 72px;
}
.courses-details-desc-style-two .details-title {
  color: #2c292a;
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 1px;
}
.courses-details-content .left-side {
  width: calc(100% - 452px);
  padding-right: 16px;
}
.courses-details-content .right-side {
  width: 452px;
}
.courses-details-desc-style-two .details-content {
  margin-bottom: 55px;
}
.courses-details-desc-style-two .details-content p {
  color: #2c292a;
  font-size: 20px;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.courses-details-desc-style-two .details-title:first-child {
  padding-bottom: 14px;
  display: block;
  margin-bottom: 28px;
  border-bottom: 2px solid #2c292a;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  z-index: 1;
  width: 100%;
}
.courses-details-desc-style-two .details-title:first-child .open-close {
  margin-left: auto;
  transition: 0.2s ease-in;
}
.courses-details-desc-style-two .details-title + div {
  transition: 0.2s ease-in;
  max-height: 100%;
  overflow: hidden;
  opacity: 1;
  visibility: visible;
}
.courses-details-desc-style-two .details-title.active + div {
  max-height: 0;
  opacity: 0;
  margin-bottom: 0;
  visibility: hidden;
}
.courses-details-desc-style-two .details-title.active:first-child .open-close,
.message-bubble .close-button:before {
  transform: rotate(45deg);
}
.courses-details-desc-style-two .content-videos .courses-curriculum {
  margin-bottom: 71px;
}
.courses-details-desc-style-two h3.title {
  display: flex;
  align-items: center;
  color: #2c292a;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  margin-bottom: 24px;
  letter-spacing: 1.6px;
}
.courses-details-desc-style-two .sub {
  color: #2c292a;
  font-size: 24px;
  font-weight: 300;
  line-height: 33.6px;
  letter-spacing: 1.2px;
  margin-bottom: 24px;
}
.courses-details-desc-style-two
  .courses-curriculum
  ul
  li
  .courses-name:first-letter,
.courses-details-desc-style-two h3.title::first-letter,
.flag-button :first-letter,
.quiz-container ul li::first-letter,
.video-area .right-side .video-sidebar .title:first-letter {
  text-transform: uppercase;
}
.courses-details-desc-style-two h3.title .img {
  margin-left: 20px;
}
.courses-details-desc-style-two .why-you-learn ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 15px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -12px;
}
.courses-details-desc-style-two .why-you-learn ul li {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 12px;
}
.courses-details-desc-style-two .why-you-learn ul li span {
  display: block;
  color: #221638;
  position: relative;
  padding-left: 22px;
  line-height: 1.7;
  font-weight: 500;
}
.courses-details-desc-style-two .why-you-learn ul li span i {
  position: absolute;
  left: 0;
  top: 3px;
  color: #ffc32b;
  font-size: 13px;
}
.courses-details-desc-style-two .courses-curriculum h3 {
  margin-top: 0;
  margin-bottom: 18px;
  font-size: 18px;
}
.courses-details-desc-style-two .courses-curriculum ul li {
  background-color: #f5f5f5;
  color: #2c292a;
  border: 1px solid rgba(44, 41, 42, 0.05);
  position: relative;
  padding: 20px;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  text-transform: lowercase;
  margin-bottom: 16px;
}
.courses-details-desc-style-two .courses-curriculum .course-line {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
}
.courses-details-desc-style-two .courses-curriculum ul li .courses-name {
  font-size: 18px;
  letter-spacing: 0.9px;
  line-height: 28.8px;
  margin: 0 32px 0 12px;
  font-weight: 500;
}
.checkout-area .cart-side-left .list .item:last-child,
.courses-details-desc-style-two .courses-curriculum ul li .courses-right,
.footer-bottom .right-block {
  margin-left: auto;
}
.courses-details-desc-style-two .courses-curriculum ul li .courses-meta {
  text-align: right;
  margin-right: 5px;
}
.courses-details-desc-style-two
  .courses-curriculum
  ul
  li
  .courses-meta
  .duration {
  background: rgba(251, 183, 35, 0.15);
  padding: 1px 6.5px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 800;
  line-height: 24px;
  min-width: 50px;
  display: inline-block;
}
.courses-details-desc-style-two
  .courses-curriculum
  ul
  li
  .courses-meta
  .status {
  display: inline-block;
  background: #221638;
  color: #fff;
  border-radius: 3px;
  margin-left: 6px;
  padding: 2px 10px 1.5px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
}
.courses-details-desc-style-two .audience-list,
.courses-details-desc-style-two .requirements-list {
  padding-left: 15px;
  margin-bottom: 0;
  list-style-type: none;
}
.courses-details-desc-style-two .requirements-list li {
  color: #606060;
  margin-bottom: 10px;
  position: relative;
  padding-left: 17px;
}
.courses-details-desc-style-two .requirements-list li::before {
  position: absolute;
  left: 0;
  content: "";
  top: 7px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #221638;
}
.courses-details-desc-style-two .description-features-list {
  padding-left: 15px;
  margin-bottom: 15px;
  list-style-type: none;
}
.courses-details-desc-style-two .audience-list li,
.courses-details-desc-style-two .description-features-list li {
  color: #606060;
  margin-bottom: 10px;
  position: relative;
  padding-left: 17px;
  line-height: 1.8;
}
.courses-details-desc-style-two .audience-list li::before,
.courses-details-desc-style-two .description-features-list li::before {
  position: absolute;
  left: 0;
  content: "";
  top: 10px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #221638;
}
.courses-details-desc-style-two .courses-author {
  margin-top: 20px;
  border-radius: 16px;
  background: #f8f8f8;
  padding: 22px;
}
.courses-details-desc-style-two .courses-author .author-name {
  color: #2c292a;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.courses-details-desc-style-two .courses-author .author-name + .d-block {
  color: #2c292a;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.8px;
  margin-bottom: 35px;
}
.courses-details-desc-style-two .courses-author .author-bio {
  color: #2c292a;
  font-size: 20px;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 1px;
}
.courses-details-desc-style-two
  .courses-author
  .author-profile
  .author-profile-title {
  padding-right: 24px;
  width: 122px;
}
.courses-details-desc-style-two .courses-author .author-right {
  width: calc(100% - 122px);
}
.courses-details-desc-style-two
  .courses-author
  .author-profile
  .author-profile-title
  img {
  width: 98px;
  border-radius: 50%;
}
.courses-details-desc-style-two
  .courses-author
  .author-profile
  .author-profile-title
  .author-profile-title-details,
.subscribe-content .newsletter-form .validation-success {
  margin-top: 15px;
}
.courses-details-desc-style-two
  .courses-author
  .author-profile
  .author-profile-title
  .author-profile-title-details
  .author-profile-details
  h4 {
  margin-bottom: 5px;
  font-size: 22px;
}
.courses-details-desc-style-two
  .courses-author
  .author-profile
  .author-profile-title
  .author-profile-title-details
  .author-profile-details
  span {
  color: #606060;
}
.courses-details-desc-style-two .courses-reviews h3 {
  margin-bottom: 0;
  display: inline-block;
  margin-right: 15px;
  font-size: 24px;
}
.courses-details-desc-style-two .courses-reviews .rating {
  display: inline-block;
  position: relative;
  top: 2px;
}
.courses-details-desc-style-two .courses-reviews .rating span {
  font-size: 17px;
  color: #cecfd2;
  margin-right: 2px;
}
.courses-review-comments h3 {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 10px;
  margin-top: 0;
  margin-bottom: 10px;
}
.courses-review-comments .user-review {
  border-bottom: 1px solid #f3f3f3;
  padding: 20px 0 20px 110px;
  position: relative;
}
.courses-review-comments .user-review img {
  position: absolute;
  left: 0;
  top: 20px;
  width: 90px;
  height: 90px;
  border-radius: 5px;
}
.courses-review-comments .user-review .sub-comment {
  margin-bottom: 8px;
  font-weight: 700;
}
.courses-review-comments .user-review .review-rating .review-stars i {
  color: #cecfd2;
  font-size: 18px;
  display: inline-block;
  margin-right: 2px;
}
.courses-review-comments .user-review .review-rating span {
  color: #221638;
  position: relative;
  top: -2px;
  font-weight: 700;
  margin-left: 5px;
}
.related-courses h3 {
  margin-bottom: 20px;
  font-size: 24px;
}
.courses-sidebar-information {
  background-color: #f5f5f5;
  border-radius: 16px;
  border: 2px solid rgba(44, 41, 42, 0.05);
  padding: 24px;
}
.courses-sidebar-information .price {
  color: #2c292a;
  font-size: 40px;
  font-weight: 700;
  display: block;
  line-height: 48px;
  letter-spacing: 2px;
  margin-bottom: 34px;
}
.courses-sidebar-information .item-info {
  display: flex;
  flex-direction: row;
  color: #2c292a;
}
.courses-sidebar-information .info {
  margin-bottom: 0;
  list-style-type: none;
  padding-left: 0;
}
.courses-sidebar-information .info li span {
  font-weight: 400;
  color: #2c292a;
  position: relative;
  padding: 0 8px;
  font-size: 14px;
  line-height: 19.6px;
  letter-spacing: 0.7px;
}
.courses-sidebar-information .info li strong {
  color: #2c292a;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
  letter-spacing: 0.7px;
}
.courses-sidebar-information .btn-box .default-btn {
  display: block;
  width: 100%;
  padding: 12px 30px;
}
.courses-sidebar-information .courses-share {
  text-align: center;
  margin-top: 25px;
}
.courses-sidebar-information .courses-share .share-info {
  display: inline-block;
  position: relative;
}
.courses-sidebar-information .courses-share .share-info .social-link {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 12px;
}
.courses-sidebar-information .coupon {
  margin-top: 16px;
}
.courses-sidebar-information .coupon h4 {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
  letter-spacing: 0.28px;
  line-height: 24px;
  cursor: pointer;
  text-decoration-line: underline;
  text-align: center;
}
.courses-sidebar-information .coupon form {
  position: relative;
  width: 100%;
  border-radius: 5px;
}
.courses-sidebar-information .coupon form .input-search {
  display: block;
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 5px;
  background-color: #f5f5f5;
  transition: 0.5s;
  color: #221638;
  font-size: 16px;
  font-weight: 400;
  padding: 1px 0 0 15px;
}
.courses-sidebar-information .coupon form .input-search::-moz-placeholder {
  color: #848484;
  -moz-transition: 0.5s;
  transition: 0.5s;
}
.card-custom{
  background-color: white;
}
.courses-sidebar-information .coupon form .input-search::placeholder {
  color: #848484;
  transition: 0.5s;
}
.courses-sidebar-information .coupon form .input-search:focus,
.login-form form .remember-me-wrap [type="checkbox"]:checked + label:before,
.login-form form .remember-me-wrap [type="checkbox"]:hover + label:before {
  border-color: #ffc32b;
}
.courses-sidebar-information
  .coupon
  form
  .input-search:focus::-moz-placeholder {
  color: transparent;
}
.courses-sidebar-information .coupon form .input-search:focus::placeholder {
  color: transparent;
}
.courses-sidebar-information .coupon form button {
  position: absolute;
  right: 0;
  top: 0;
  height: 48px;
  background-color: #ffc32b;
  border: none;
  color: #fff;
  border-radius: 5px;
  padding: 0 15px;
}
.statisfyimage{
  position: relative;
  z-index: 9999999;
  bottom: 142px;
  left: 84%;
}
.grentyimage{
  position: relative;
  top: 84px;
  left: 84%;
}
@media (max-width: 1120px) {
  .courses-details-content .left-side {
    width: calc(100% - 350px);
  }
  .courses-details-content .right-side {
    width: 350px;
  }
  .main-title-hero{
    min-width: auto;
  }
  .footer-content .item p{
    text-align: center !important;
  }
  .mobileer .animation-container{
    left: 43%;
  }
}
.card-custom{
  padding: 20px;
}
.toper2{
  margin-top: 35px;
}
.imageofallflags{
  width: 60%;
  margin-top: 15px;
  margin-bottom: 10px;
}
.anothertitleforcards{
  display: none;
}

.rightimagecontent{

  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: inherit;
  flex-wrap: nowrap;
}
.line-flag {
  width: 289px;
  height: 49px;
}
.imageforbanner{
  display: none;
}
@media (max-width: 991px) {
 .rightimagecontent{
  padding-bottom: 0px;
 }
 .imageforbanner{
  text-align: center;
  /* margin-bottom: 50px; */
  position: relative;
  bottom: 29px;
  display: block;
}
@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-image {
  animation: rotate360 10s linear infinite; /* Adjust duration and timing function as needed */
}


.courses-area{
padding-top: 0px !important;
}
}
.maincontainerforstudent .imageforbanner{
  display: none;
}
.mobileabout{
  display: none;
}
.about-content .sub{
  max-width: 573px;
  padding-top: 30px;
}
.buttonofcourse{
  width: 347px;
  margin-top: 55px;
}
.textofdesk{
  max-width: 573px;
  color: #2C292A;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 300;
line-height: 160%; /* 25.6px */
letter-spacing: 0.8px;
}
  .about-content .sub{
max-width: 884px;
  }
.scrolled-navbar {
  position: sticky;
  top: 0;
  height: 72px;
  border-bottom: 2px solid #EEE;
  background: #FFF;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  animation: fadeInNavbar 1s ease; /* Add the animation */
}
.nameofclass{
  color: rgba(45, 39, 39, 0.70);
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 300;
line-height: 140%; /* 28px */
}
.redprice{
  color: #FF3030;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 14.4px */
display: flex;
width: 65px;
height: 22px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
background: #FFD6D6;
}
.red-cover{
  display: flex;
  gap: 7px;
  align-items: center;
}
.coverofextra{
  color: #2D2727;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 28px */
}
.buysmallbutton{
  display: flex;
width: 85px;
height: 48px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 8px;
background: #FBB723;
color: #2D2727;
font-family: Heebo,sans-serif;
font-size: 15px;
font-style: normal;
font-weight: bold;
line-height: 120%; /* 18px */
}
.coverofnavbar{
  width: 83vw;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.crossign{
  z-index: 9999;
  color: white;
}
@keyframes fadeInNavbar {
  from {
    opacity: 0;
    transform: translateY(-20px); /* Move the navbar slightly up */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Move the navbar back to its original position */
  }
}

.scrolled-navbar {
  position: sticky;
  top: 0;
  height: 72px;
  border-bottom: 2px solid #EEE;
  background: #FFF;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  animation: fadeInNavbar 1s ease; /* Add the animation */
}

img {
    image-rendering: auto; /* or pixelated */
}

@media (max-width: 786px) {
  .subscribe-content .newsletter-form{
    width: 100% !important;
  }
  .footer-content .item:last-child{
    margin-top: 30px;
  }
  .coverofdisclaimer .call-to-phone-block{
    margin-top: 191px;
  }
  .homereview .coverofreviewslider {
    bottom: 133px;
  }
  .callphone-block .left-side img{
    max-height: 344px;
  }
  .footer-area .footer-content{
    border-top: none !important;
    padding-top: 0 !important;
  }
  .navbar-area.is-home{
    padding:0;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .about-area{
padding-top: 0px;
  }
  .left-hero-block{
    max-width: 100%;
  }
  .banner-animation-container img{
    max-width: 80%;
    position: relative;
    left: 7px;
  }
  .rightimagecontent{
    max-width: 99%;
  }
  .about-area .about-area-block{
    padding: 0;
  }
  .about-area{
    padding: 0;
  }
  /* .primary-button{
    width: 100px;
  } */
  .primary-button, .secondary-button{
    width: 100% !important;
  }
  .left-hero-block .main-subtitle{
    margin-bottom: 10px;
  }
  .main-title-hero{
    margin-bottom: 0px;
  }
 
  .left-hero-block .main-block-buttons {
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
}
  .css-1j1w2hx{
    flex-wrap: wrap;
  }
  .call-to-phone-block{
    padding-top: 0px !important;
  }
  .mobileabout{
    display: block;
  }
  .desktopabout{
    display: none;
  }
  .call-to-phone-block .container-full{
    padding: 0px;
  }
  .call-to-phone-block .container-full .callphone-block{
    border-radius: 0px;
    background-image: url(./cover.png);
    flex-direction: column-reverse;
    height: 394px !important;
    width: 90vw;
    border-radius: 23px;
  }
  .left-side{
    display: block !important;
    margin-top: 50px;
  }
  .callphone-block .left-side img{
    display: block !important;
  }
  .callphone-block .right-side p{
    color: #FFF;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 140%; /* 22.4px */
letter-spacing: 0.64px;
width: 295px;
/* margin-bottom: 50px !important; */
  }
  
  .callphone-block .right-side h3{
    color: #FFF;
font-family: Inter;
font-size: 24px !important;
font-style: normal;
font-weight: 800;
line-height: 120%; /* 28.8px */
letter-spacing: 1.2px;
margin-bottom: 16px !important;
  }
  .callphone-block .right-side .right-content .text-block{
    margin-top: 24px;
  }
  .about-area .about-content .sub-title{
    margin-bottom: 12px ;
  }
  .about-area .about-content h2{
    margin-bottom: 24px;
  }
  .feedback-content .sub-title{
    margin-bottom: 24px;
  }
  .feedback-content .title{
    margin-bottom: 24px;
  }
  .feedback-content .sub{
    margin-bottom: 50px;
  }
  .subscribe-area {
    margin-top: 200px;
  }
  .rightslidercover{
    position: relative;
  }
 
  

  .coverformobileversionofclassa .slick-dots.slick-dots-bottom{
    bottom: -15px;
  }
  .slick-dots.slick-dots-bottom{
    position: relative;
    top: 30px;
  }
  .footer-area{
    margin-top: 140px;
  }
  .feedback-area{
    height: 689px !important;
  }
  .subscribe-area{
    display: none;
  }

  .card-custom {
    border-radius: 16px;
  }
  .anothertitleforcards{
    display: block;
  }
  .toper2{
    margin-top: 10px;
  }
  .Iconscovers{
    display: none;
  }
.about_formobileview{
  padding: 20px;
}
  .titleforcards{
    display: none;
  }
  .card-custom{
    height: 327px !important;
  }
  .about-area .about-content{
    text-align: left !important;
  }
  .about-area .about-image{
    width: 100% !important;
    height: auto;
    margin-top: 50px !important ;
  }
  .footer-content .email-bottom{
    padding-right: 0px !important;
  }
  .card-custom{
    padding-left: 8px;
    padding-right: 5px;
  }
 
  
  .footer-area{
    padding-top: 0px !important;
    border-top: none !important;
  }
  .footer-content{
    margin-bottom: 0px !important;
    row-gap: 0px !important;
  }
  .footer-content .item{
    padding-top: 0px !important;
    padding: 0px !important;
  }
}

.instructor-header-content {
  display: flex;
  flex-direction: row;
  border-radius: 16px;
  border: 2px solid rgba(44, 41, 42, 0.05);
  background: #f5f5f5;
  width: 100%;
  align-items: center;
  padding: 30px 22px;
  margin-bottom: 20px;
}
.bg-F6F1ED,
.call-to-phone-block,
.disclaimer {
  background: #fff;
}
.basic-info .content-information .left-side,
.basic-info .content-information .right-side,
.instructor-header-content > div {
  width: 50%;
}
.advisor-area {
  padding: 120px 0 0;
}
.advisor-area .content-instructor {
  flex-direction: row;
  display: flex;
  margin: auto;
  width: 100%;
}
.advisor-area .content-instructor .single-advisor-box {
  width: calc(25% - 24px);
  padding: 26px 27px 45px 28px;
  border: 3px solid rgba(44, 41, 42, 0.05);
  border-radius: 12px;
  margin-right: 24px;
}
.advisor-area .content-instructor .single-advisor-box img {
  display: block;
  width: 100%;
}
.advisor-area .content-instructor h3 {
  color: #2c292a;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1px;
  display: block;
  margin: 24px 0 0;
}
.advisor-area .content-instructor span {
  color: #fbb723;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  letter-spacing: 0.8px;
  margin: 17px 0;
  display: block;
}
.advisor-area .content-instructor p {
  color: #2c292a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  display: block;
  margin-bottom: 20px;
  letter-spacing: 0.8px;
}
.advisor-area .content-instructor .social-link li {
  display: block;
  margin-right: 10px;
}
.advisor-area .content-instructor .social-link a {
  width: 32px;
  height: 32px;
  background: #fbb723;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  padding: 5px;
}
.advisor-area .content-instructor .social-link a svg {
  display: block;
  width: 22px;
  height: 22px;
}
.slick-slider slick-initialized{
  padding: 7px;
}
@media (max-width: 1200px) {
  .advisor-area .content-instructor {
    flex-wrap: wrap;
  }
  .about-image img{
    height: 600px;
  }
  .about-content .sub{
    padding-top: 0px;
  }
  .about-image{
height: 100%;
  }
  .advisor-area .content-instructor .single-advisor-box {
    width: calc(33.333333% - 24px);
    margin-bottom: 24px;
  }
}
@media (max-width: 990px) {
  .courses-details-desc-style-two .details-title {
    font-size: 20px;
    line-height: 24px;
  }
  .courses-details-desc-style-two .details-content p {
    font-size: 18px;
  }
 
  .about-area .about-area-block{
    justify-content: left;
  }
  .courses-details-desc-style-two .courses-curriculum ul li .courses-name {
    font-size: 14px;
  }
  .courses-details-content,
  .courses-details-desc-style-two .courses-author .author-profile {
    flex-direction: column;
  }
  .courses-sidebar-information {
    margin-top: 41px;
  }
  .courses-details-content .left-side {
    width: 100%;
    padding: 0;
    margin-bottom: 0;
  }
  .sidebar-details.mobile {
    display: block;
  }
  .courses-details-content .right-side.desktop {
    display: none;
  }
  .courses-details-desc-style-two .courses-author .author-name + .d-block {
    margin-bottom: 12px;
  }
  .courses-details-desc-style-two
    .courses-author
    .author-profile
    .author-profile-title,
  .courses-details-desc-style-two .courses-author .author-right {
    width: 100%;
    padding: 0;
  }
  .advisor-area .content-instructor .single-advisor-box {
    width: calc(50% - 24px);
    margin-right: 0 !important;
  }
  .advisor-area {
    padding: 64px 0;
  }
  .advisor-area .section-title {
    margin-bottom: 26px;
  }
}
@media (max-width: 650px) {
  .advisor-area .content-instructor .single-advisor-box {
    width: 100%;
  }
}
.disclaimer {
  position: relative;
  padding: 80px 0 90px;
  margin-top: -39px;
}
.disclaimer .line-flags {
  display: flex;
  flex-direction: row;
  margin: 0 0 54px 6px;
}
.disclaimer .line-flags .img {
  position: relative;
  margin-left: -9px;
}
.disclaimer .line-flags .img:first-child {
  border: 1px solid #fff;
  border-radius: 50%;
  transform: scale(0.9);
}
.disclaimer .line-flags .img img {
  display: block;
  width: 40px;
  height: 40px;
}
.disclaimer .line-numbers {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.disclaimer .line-numbers .block-number {
  padding: 0 42px;
  text-align: center;
  border-right: 3px solid rgba(44, 41, 42, 0.05);
}
.disclaimer .line-numbers .block-number .text {
  color: #2c292a;
  font-size: 20px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 1px;
  white-space: nowrap;
}
.fun-facts-block {
  display: flex;
  flex-direction: row;
  z-index: 2;
  position: relative;
}
.disclaimer .feedback-image {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 100%;
  max-width: 1198px;
}
.disclaimer .line-numbers .block-number .number {
  color: #2c292a;
  font-size: 40px;
  text-align: center;
  font-weight: 800;
  line-height: 48px;
  letter-spacing: 2px;
}
@media (max-width: 1700px) {
  .disclaimer .feedback-image {
    max-width: 1000px;
  }
  .disclaimer {
    padding: 80px 0 400px;
  }
}
@media (max-width: 1425px) {
  .disclaimer .feedback-image {
    max-width: 900px;
  }
  .disclaimer {
    padding: 80px 0 250px;
  }
}
@media (max-width: 1350px) {
  .disclaimer .feedback-image {
    max-width: 700px;
  }
  .disclaimer {
    padding: 80px 0 200px;
  }
}
@media (max-width: 1150px) {
  .disclaimer .feedback-image {
    max-width: 650px;
  }
}
@media (max-width: 990px) {
  .feedback-content {
    max-width: initial;
  }
  .disclaimer .feedback-image {
    display: none;
  }
  .disclaimer {
    margin-top: -80px;
    padding: 149px 0 90px;
  }
  .disclaimer .line-numbers .block-number .number {
    font-size: 30px;
    line-height: initial;
  }
}
@media (max-width: 535px) {
  .disclaimer .line-numbers {
    justify-content: flex-start;
  }
  .disclaimer .line-numbers .block-number {
    padding: 0 24px;
    width: 33.333333%;
    flex-wrap: wrap;
  }
}
@media (max-width: 480px) {
  .logo-nav{
    max-width: 180px;
  }
  .disclaimer .line-numbers .block-number {
    padding: 0 16px;
  }
  .disclaimer .line-numbers .block-number .text {
    white-space: initial;
    font-size: 16px;
    line-height: initial;
  }
  .disclaimer .line-numbers .block-number .number {
    font-size: 24px;
    line-height: 28.8px;
  }
}
.funfacts-list .single-funfacts-box {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  margin-bottom: 25px;
}
.callphone-block {
  display: flex;
  flex-direction: row;
  position: relative;
  max-width: 1554px;
  background: url("https://res.cloudinary.com/dezhgxayn/image/upload/v1706950792/Group_1171278622_licrlb.png") center/cover
    no-repeat;
  margin: auto;
  border-radius: 16px;
  height: 303px;
}
.about-area .container{
  padding: 0px;
}
.callphone-block .left-side {
  top: -113px;
  position: absolute;
  left: 170px;
  z-index: 1;
}
.callphone-block .right-side {
  margin-left: auto;
  margin-right: 243px;
  max-width: 578px;
  min-width: 578px;
  padding: 80px 0;
}
.callphone-block .right-side h3 {
  font-size: 32px;
  line-height: 28.8px;
  color: #fff;
  letter-spacing: 1.2px;
  font-weight: 800;
  margin-bottom: 8px;
  color:"white"
}
.callphone-block .right-side p {
  font-size: 16px;
  font-style: normal;
  color: #2c292a;
  font-weight: 300;
  margin-bottom: 15px;
  line-height: 25px;
  max-width: 450px;
  display: block;
  color: whitesmoke;
}
.callphone-block .button-block,
.checkout-area .cart-side-left .list {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.callphone-block .button-block button {
  border-radius: 8px;
  color: #2c292a;
  background: #fbb723;
  width: calc(100% - 24px);
  transition: 0.2s ease-in;
  margin-right: 12px;
  line-height: 54px;
  border: 2px solid #fbb723;
  letter-spacing: 0.32px;
  padding: 10px 0px;
  

}
.coverofbannerimage{
  position: relative;
  position: relative;
  display: flex;
  /* align-items: center; */
  top: 361px;
  left: 5px;
}
.rotater{
  width: 195px;
}
@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotater {
  animation: rotate360 10s linear infinite; /* Adjust duration and timing function as needed */
}
.callphone-block .button-block button svg,
.contact-info .line-icons-contact li .icon {
  margin-right: 8px;
}
.callphone-block .button-block button:last-child {
  margin-left: 12px;
  margin-right: 0;
}
@media (max-width: 1315px) {
  .callphone-block .right-side {
    margin-right: 30px;
  }
}
@media (max-width: 1160px) {
  .courses-item .courses-block .desktop {
    display: none;
  }
  .courses-item .courses-block .mobile {
    display: block;
  }
  .callphone-block .left-side {
    bottom: 0;
    top: initial;
    max-width: 300px;
    left: 20px;
  }
  .callphone-block .left-side img {
    display: block;
    margin: auto;
  }
}
@media (max-width: 1080px){
  .callphone-block .right-side {
    margin-right: 0px;
    padding-left: 60px;
    margin-left: 0px;
}
.card-custom{
  height: 408px;
}
.bottomemail{
  padding-top: 37px !important;
}
.left-side{
  display: none;
}
.footer-content {
  padding-bottom: 51px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 50px;
}
}
@media (max-width: 1050px) {
  .callphone-block .right-side {
    padding: 20px 0;
    padding-left: 20px;
  }
  .callphone-block .left-side {
    bottom: 0;
    top: initial;
    max-width: 250px;
  }
}
@media (max-width: 450px) {
  .courses-details-desc-style-two .courses-curriculum ul li .courses-name {
    line-height: initial;
    margin: 0 15px 0 0;
  }
  .courses-details-desc-style-two .courses-curriculum .course-line .icon {
    display: none;
  }
  .courses-details-desc-style-two .courses-curriculum ul li {
    padding: 22px 14px;
  }
  .callphone-block .button-block button svg {
    margin-right: 4px;
  }
}
.funfacts-area-two {
  border-top: 1px solid #f4f3f5;
  border-bottom: 1px solid #f4f3f5;
}
.funfacts-area-two .col-lg-3 {
  border-right: 1px solid #f4f3f5;
}
.funfacts-area-two .col-lg-3:last-child {
  border-right: none;
}
.single-funfacts {
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-top: 100px;
  padding-bottom: 100px;
}
.single-funfacts img {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0.8;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.single-funfacts h3 {
  margin-bottom: 0;
  color: #ffc32b;
  font-size: 44px;
}
.single-funfacts h3 span {
  font-size: 48px;
}
.single-funfacts h3 .odometer {
  position: relative;
  top: -2px;
}
.single-funfacts p {
  text-transform: uppercase;
  color: #221638;
  line-height: 1;
  margin-top: 8px;
  font-weight: 700;
  font-size: 16px;
}
.single-feedback-item p,
.video-area .right-side .text {
  font-weight: 400;
  letter-spacing: 0.8px;
}

.feedback-content{
  align-items: center;
}
.feedback-area .right-block {
  max-width: 554px;
  margin: 50px 0;
  position: relative;
  margin-left: auto;
}
.disclaimer .feedback-content {
  max-width: 495px;
}
.feedback-content .sub-title {
  display: block;
  color: #ffc32b;
  letter-spacing: 1px;
  margin-bottom: 0;
  line-height: 32px;
  font-size: 20px;
  font-weight: 500;
}
.feedback-content .title {
  font-size: 40px;
  line-height: 48px;
  font-weight: 800;
  letter-spacing: 2px;
  max-width: 700px;
}
.feedback-content .sub,
.single-feedback-item p {
  color: #2c292a;
  line-height: 25.6px;
  font-size: 16px;
}
.feedback-content .sub {
  font-weight: 300;
  letter-spacing: 1px;
}
.feedback-content .feedback-info p {
  font-weight: 600;
  margin-bottom: 0;
}
.feedback-content .feedback-info p a {
  color: #ffc32b;
  font-weight: 700;
}
.feedback-content
  .feedback-slides
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: rgba(255, 195, 43, 0.5);
}
.feedback-area .right-block:before {
  background: url('data:image/svg+xml,<svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="72" height="72" rx="16" fill="%23FBB723"/><path d="M29.2609 48.2468C28.1225 48.2468 27.0601 47.8137 26.0735 46.9477C25.1249 46.044 24.366 44.8768 23.7968 43.4459C23.2656 42.0151 23 40.4901 23 38.871C23 36.3859 23.4364 34.0513 24.3091 31.8674C25.2198 29.6459 26.3202 27.782 27.6103 26.2759C28.9004 24.7321 30.1336 23.6401 31.3099 23H31.3668L33.3589 24.8074V25.5981C32.4103 26.4641 31.5186 27.7067 30.6838 29.3258C29.849 30.9073 29.4316 32.8652 29.4316 35.1998C29.4316 36.4047 29.6024 37.3837 29.9439 38.1368C30.2854 38.8898 30.7976 39.7182 31.4806 40.6219C32.0498 41.3373 32.4672 41.9586 32.7328 42.4858C32.9984 43.0129 33.1312 43.653 33.1312 44.4061C33.1312 45.5357 32.7518 46.4582 31.9929 47.1736C31.2719 47.889 30.3613 48.2468 29.2609 48.2468ZM45.0838 48.2468C43.9455 48.2468 42.902 47.8137 41.9534 46.9477C41.0047 46.044 40.2458 44.8768 39.6767 43.4459C39.1455 42.0151 38.8798 40.4901 38.8798 38.871C38.8798 36.3859 39.3162 34.0513 40.1889 31.8674C41.0996 29.6459 42.2 27.7632 43.4901 26.2194C44.7802 24.6756 46.0134 23.6025 47.1897 23H47.2466L49.1818 24.8074V25.5981C48.2332 26.4641 47.3415 27.7067 46.5067 29.3258C45.6719 30.9073 45.2545 32.8652 45.2545 35.1998C45.2545 36.4047 45.4253 37.3837 45.7668 38.1368C46.1083 38.8898 46.6206 39.7182 47.3036 40.6219C47.8727 41.3373 48.2901 41.9586 48.5557 42.4858C48.8213 43.0129 48.9541 43.653 48.9541 44.4061C48.9541 45.5357 48.5747 46.4582 47.8158 47.1736C47.0949 47.889 46.1842 48.2468 45.0838 48.2468Z" fill="%232C292A"/></svg>')
    no-repeat;
  width: 72px;
  height: 72px;
  position: absolute;
  left: 20px;
  top: -36px;
  margin: auto;
  display: block;
  content: "";
  z-index: 10;
}
.single-feedback-item {
  padding-bottom: 55px;
}
.single-feedback-item .background-white {
  background-color: #fff;
  position: relative;
  z-index: 1;
  padding: 59px 55px 24px 32px;
  border-radius: 16px;
  min-height: 403px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.single-feedback-item .client-info {
  margin-top: auto;
}
.single-feedback-item .client-info img {
  width: 55px !important;
  height: 55px;
}
.single-feedback-item .client-info .title {
  margin-left: 15px;
}
.single-feedback-item .client-info .title h3 {
  margin-bottom: 0;
  color: #ffc32b;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
}
.single-feedback-item .client-info .title span {
  display: block;
  color: #2c292a;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.8px;
  line-height: initial;
}
.testimonials-slides {
  max-width: 900px;
}
.single-testimonials-item {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  padding-left: 280px;
}
.single-testimonials-item .client-img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 150px !important;
  height: 150px;
  border-radius: 50%;
  display: inline-block !important;
  left: 35px;
}
.live-class-icon,
.video-box .video-btn {
  transform: translateY(-50%) translateX(-50%);
}
.single-testimonials-item p {
  color: #221638;
  font-size: 20px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
}
.single-testimonials-item h3 {
  color: #ffc32b;
  margin-top: 18px;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
}
.single-testimonials-item span {
  display: block;
  color: #606060;
  font-size: 15px;
  margin-top: 4px;
}
.feedback-content .swiper-pagination-clickable .swiper-pagination-bullet {
  width: 172px;
  transform: scale(1);
  border-radius: 14px;
  background: rgba(255, 236, 195, 0.5);
  margin: 0 9.5px;
}
.single-testimonials-item::before {
  content: "\f10a";
  position: absolute;
  right: 0;
  color: #e8e8e8;
  z-index: -1;
  bottom: 0;
  line-height: 1;
  font-family: Flaticon;
  font-size: 100px;
}
.single-testimonials-item .shape-img img {
  width: auto !important;
  display: inline-block !important;
  position: absolute;
}
.single-testimonials-item .shape-img img.shape-1 {
  position: absolute;
  left: 13%;
  top: 11%;
  z-index: -1;
  animation: 4s linear infinite movescale;
}
.single-testimonials-item .shape-img img.shape-2 {
  left: 0;
  top: 8px;
  z-index: -3;
  animation-name: rotateme;
  animation-duration: 100s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.single-testimonials-item .shape-img img.shape-3 {
  left: 20px;
  animation: 4s linear infinite moveleftbounce;
  bottom: -30px;
  z-index: -2;
}
.slick-list{
  height: auto !important;
}
.feedback-area {
  background: url("./hero-background.png") center no-repeat;
  color: #fff;
  position: relative;
  z-index: 1;
  padding: 50px 0px;
  height: auto;
  padding-top: 0px;
}
.video-area .video-content #react-doc-viewer {
  border-radius: 16px;
  background: #c4c4c4;
  height: 70vh;
  width: 100%;
}
#loading-renderer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.video-area {
  padding: 64px 0 113px;
}
.video-area .left-side {
  width: 938px;
  padding-right: 18px;
}
.video-area .right-side {
  width: calc(100% - 938px);
}
.video-area .right-side .text {
  color: #2c292a;
  font-size: 16px;
  line-height: 19.2px;
  margin-bottom: 26px;
}
.video-area .right-side .text .info-certificate {
  font-weight: 700;
  text-decoration: underline;
  padding-left: 5px;
}
.video-area .right-side .video-sidebar .title {
  color: #2c292a;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  letter-spacing: 1.2px;
  text-transform: lowercase;
}
.video-area .right-side .progress {
  overflow: initial;
  border-radius: 15px;
}
.video-area .right-side .progress-bar {
  position: relative;
  display: block;
  overflow: initial;
  border-radius: 15px;
}
.video-area .right-side .progress-bar .circle {
  width: 48px;
  height: 48px;
  background: #fbb723;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 800;
  line-height: 24px;
  color: #2c292a;
  align-items: center;
  margin: auto;
  right: -10px;
  bottom: 0;
  top: 0;
  position: absolute;
  border-radius: 50%;
}
.video-area .courses-details-desc-style-two .courses-curriculum ul li {
  padding: 15px 14px;
  transition: 0.2s ease-in;
  cursor: pointer;
}
.quiz-container button:hover,
.quiz-container ul .selected-answer,
.video-area .courses-details-desc-style-two .courses-curriculum ul li.active,
.video-area .courses-details-desc-style-two .courses-curriculum ul li:hover {
  background: #2c292a;
  color: #ffc32b;
}
.video-box {
  position: relative;
  border-radius: 5px;
  max-width: 800px;
  z-index: 1;
  margin-top: 100px;
}
.video-box .image {
  overflow: hidden;
  border-radius: 5px;
}
.video-box .image img {
  border-radius: 5px;
  transition: 0.5s;
}
.video-box .video-btn {
  display: inline-block;
  width: 80px;
  height: 80px;
  font-size: 35px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  background-color: #ffc32b;
  border-radius: 50%;
  color: #fff;
}
.video-box .video-btn i {
  padding-left: 3px;
}
.video-box .video-btn::after,
.video-box .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  transition: 0.5s;
  border-radius: 50%;
  border: 1px solid #ffc32b;
}
.live-class-icon::before,
.video-box .video-btn::before {
  animation: 2s linear infinite ripple;
}
.live-class-icon::after,
.video-box .video-btn::after {
  animation: 2s linear 1s infinite ripple;
}
.video-box .video-btn:hover {
  color: #fff;
  background-color: #221638;
}
.video-box .video-btn:hover::after,
.video-box .video-btn:hover::before {
  border-color: #221638;
}
.video-box:hover .image img {
  transform: scale3d(1.1, 1.1, 1.1);
}
@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.shape10 {
  position: absolute;
  left: -11%;
  bottom: -11%;
  z-index: -1;
}
.shape10 img {
  border-radius: 0;
  animation: 4s linear infinite moveleftbounce;
}
.video-content .pdf-viewer {
  height: 100%;
  max-height: 486px;
  width: 100%;
  overflow: hidden;
}
.video-content .pdf-viewer embed,
.video-content .pdf-viewer iframe,
.video-content .pdf-viewer object {
  border: 1px solid #c4c4c4;
  box-shadow: none;
  border-radius: 16px;
}
.coverformobileversionofclassa .footer-area{
  margin-top: 50px;
}
.call-to-phone-block{
  padding-top: 130px;
  padding-bottom: 50px;
}

.newcouselcover .slick-dots.slick-dots-bottom{
  bottom: -20px;
}
.coveroflargerscreen{
  padding-top: 50px;
  padding-bottom: 56px;
  padding-left: 20px;
}
@media (max-width:1080px) {

 
  .about-content{
    max-width: 500px !important;
  }
}
@media (max-width:1600px) {
  .coverofbannerimage{
    top: 364px;
    left: 0;
    }
}
@media (max-width:1550px) {
  .coverofbannerimage{
    top: 320px;
    left: 0;
    }
 
}
@media (max-width:1684px) {
 
  .main-title-hero{
    font-size: 70px;
      }
      .main-subtitle{
font-size: 25px;
      }
      .primary-button,.secondary-button{
        width: 231px;
        height: 52px;
        font-size: 17px;

      }
      .banner-animation-container img{
        width: 540px;
      }
}

@media (max-width:1440px) {
  .callphone-block .right-side{
    margin-right: 94px;
  }
  .about_formobileview{
    margin-top: 40px;
  }
  .footer-area{
    margin-top: 0px;
    margin-top: 1px solid #2C292A;
  }

  .about-area .about-area-block{
     display: flex;
  /* flex-direction: column-reverse; */
  /* justify-content: flex-start; */
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  }
  .about-area .about-content {
  
    max-width: 916px;
    width: auto;
    text-align: center;
}

.about-content .sub {
  /* padding-top: 30px; */
  margin: auto;
  padding-top: 0px;
}
.vousebtn{
  display: none;
}

.titleformobile{
  font-size: 40px;
  font-weight: 700;
}
.about-area .about-image{
  margin: auto;
}
 
  .coverofbannerimage{
    top: 334px;
    left: 0;
    }
  .feedback-content{
    padding-left: 20px;
    padding-right: 20px;
  }
  .feedback-content .left-block{
    width: 553px;
  }

  .about-area{
    padding-left: 30px;
    padding-right: 30px;
    column-gap: 40px;
  }
  .callphone-block .right-side .right-content .text-block{
    margin-bottom: 30px;
  }
  .callphone-block {
    display: flex;
    flex-direction: row;
    position: relative;
    max-width: 1121px;
    background: url(./bgformobileabout.png) center/cover
      no-repeat;
    margin: auto;
    border-radius: 16px;
    height: 293px;
  }

}
@media (max-width: 1300px) {
  .video-area .left-side {
    width: 700px;
  }
  .video-area .right-side {
    width: calc(100% - 700px);
  }
  .video-content .pdf-viewer {
    max-height: 486px;
  }
  .video-area .video-content #react-doc-viewer {
    height: 50vh;
  }
  .courses-details-desc-style-two .courses-curriculum ul li .courses-name {
    margin: 0 25px 0 12px;
    max-width: 280px;
  }
  .main-title-hero{
    font-size: 50px;
      }
      .main-subtitle{
font-size: 20px;
      }
      .primary-button,.secondary-button{
        height: 52px;
        font-size: 16px;

      }
      .banner-animation-container img{
        width: 540px;
      }
}
@media (max-width:1300px) {
  .callphone-block {
    background: url("https://res.cloudinary.com/dlj1daxbn/image/upload/v1704995259/Group_1171278589_ibxniz.png") center/cover
      no-repeat;
      height: 303px;
  }
  .callphone-block .left-side img{
    display: flex;
  }
  .callphone-block .right-side{
    margin-top: 0px;
    margin-right: 10px;
  }
}

@media (max-width:1200px) {
  .callphone-block .left-side img{
    display: none;
  }
  .callphone-block{
    background-image: url("https://res.cloudinary.com/dezhgxayn/image/upload/v1706876956/Group_1171278619_ltoubh.png");
  }
}
@media (max-width: 1100px) {
  .video-area .right-side {
    width: calc(100% - 600px);
  }
  .video-area .left-side {
    width: 600px;
  }
}
.get-instant {
  position: relative;
  padding: 120px 0 0;
}
.get-instant-block {
  align-items: end;
}
.get-instant-courses-content {
  max-width: 919px;
  width: calc(100% - 576px);
  padding-right: 80px;
}
.get-instant-courses-content .block-open-close li:last-child {
  margin-bottom: 40px;
}
.get-instant-courses-image {
  width: 576px;
}
.get-instant-courses-content .sub-title {
  display: block;
  text-transform: uppercase;
  color: #ffc32b;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
}
.get-instant-courses-content .title {
  font-size: 40px;
  color: #2c292a;
  margin-bottom: 16px;
  letter-spacing: 2px;
  font-weight: 800;
  line-height: 48px;
}
.subscribe-content .sub-title,
.welcome {
  line-height: 32px;
  font-weight: 500;
}
.checkout-area .cart-side-left .content .info .list span,
.contact-content p,
.contact-content ul li,
.get-instant-courses-content .sub {
  color: #2c292a;
  font-size: 20px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 1px;
}
.get-instant-courses-content .default-btn {
  margin-top: 5px;
}
.get-instant-courses-image img {
  display: block;
  height: auto;
  width: 100%;
}
.feedback-content .left-block{
  max-width: 700px;
}
@media (max-width: 1200px) {
  .get-instant-courses-image {
    width: 476px;
  }
  .get-instant-courses-content {
    width: calc(100% - 476px);
    padding-right: 60px;
  }
}
.view-all-courses-content {
  margin-left: auto;
  position: relative;
  z-index: 1;
  max-width: 600px;
}
.shape11,
.shape12,
.shape9 {
  z-index: -1;
  position: absolute;
}
.view-all-courses-content .sub-title {
  display: block;
  text-transform: uppercase;
  color: #ffc32b;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
}
.view-all-courses-content h2 {
  margin-bottom: 0;
  font-size: 36px;
}
.view-all-courses-content p {
  margin-top: 12px;
}
.view-all-courses-content .default-btn {
  margin-top: 10px;
}
.error-content,
.view-all-courses-image {
  text-align: center;
}
.shape9 {
  left: 10%;
  bottom: 20%;
}
.shape11 img,
.shape9 img {
  animation-name: rotateme;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.shape11 {
  left: 15%;
  top: 18%;
}
.shape12 {
  bottom: 18%;
  right: 18%;
}
.shape12 img {
  animation: 4s linear infinite movescale;
}
.apply-instructor-image h2 {
  margin-bottom: 18px;
  font-size: 36px;
}
.apply-instructor-image img {
  border-radius: 5px;
}
.apply-instructor-content {
  background-color: #f8f9f8;
  border-radius: 5px;
  padding: 30px;
}
.apply-instructor-content .react-tabs__tab-list {
  border-bottom-color: #d8d8d8;
  margin-bottom: 35px;
  border-width: 1.5px;
}
.apply-instructor-content .react-tabs__tab-list .react-tabs__tab {
  margin-right: 50px;
  margin-bottom: -1.5px;
  background-color: transparent;
  color: #606060;
  border: none;
  padding: 0 0 10px;
  position: relative;
  font-size: 18px;
  font-weight: 800;
}
.apply-instructor-content .react-tabs__tab-list .react-tabs__tab::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  transition: 0.5s;
  height: 1.5px;
  background-color: #ffc32b;
}
.apply-instructor-content
  .react-tabs__tab-list
  .react-tabs__tab.react-tabs__tab--selected,
.apply-instructor-content .react-tabs__tab-list .react-tabs__tab:hover {
  color: #221638;
}
.apply-instructor-content
  .react-tabs__tab-list
  .react-tabs__tab.react-tabs__tab--selected::before,
.apply-instructor-content .react-tabs__tab-list .react-tabs__tab:hover::before {
  width: 40%;
}
.apply-instructor-content .react-tabs__tab-list .react-tabs__tab:last-child,
.courses-area-list .courses-item:last-child,
.flag-button:last-child,
.footer-content .payments li:last-child,
.page-title-content ul li:last-child {
  margin-right: 0;
}
.apply-instructor-content h3 {
  margin-bottom: 13px;
  font-size: 24px;
}
.page-title-area {
  background: url("./hero-background.png") center no-repeat;
  position: relative;
  z-index: 2;
  padding: 120px 0 60px;
}
.page-title-content {
  text-align: center;
  display: flex;
  position: relative;
  justify-content: center;
}
.page-title-content h2 {
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 0;
}
.page-title-content ul {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 24px;
  top: 0;
  margin: auto;
  z-index: 1;
}
.page-title-content ul li {
  display: inline-block;
  margin-right: 20px;
  position: relative;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.2px;
}
.page-title-content ul li.active {
  color: #fbb723;
  font-weight: 700;
}
.page-title-content ul li::before {
  content: "";
  position: absolute;
  right: -10px;
  top: 4px;
  transform: rotate(10deg);
  background-color: #fff;
  height: 13px;
  width: 1px;
}
.page-title-content ul li a {
  color: #fff;
  display: block;
  font-weight: 500;
}
.page-title-content .rating {
  text-align: center;
  font-size: 17px;
  margin-top: 20px;
}
.page-title-content .rating i {
  color: #dca416;
  display: inline-block;
  margin-right: 3px;
}
.page-title-content .rating .rating-count {
  display: inline-block;
  position: relative;
  top: -2px;
  font-size: 16px;
  font-weight: 600;
}
.subscribe-area {
  position: relative;
  z-index: 1;
  /* background: url("/images/newsletter_bg_new.png") center/cover no-repeat; */
  padding: 78px 0;
}
.subscribe-content {
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.subscribe-content .sub-title {
  font-size: 20px;
  letter-spacing: 1px;
  margin-bottom: 4px;
  display: block;
}
.subscribe-content h2 {
  color: #2C292A;
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  letter-spacing: 2px;
}
.subscribe-content p {
  display: block;
  max-width: 643px;
  color: #2C292A;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 25.6px;
  letter-spacing: 0.8px;
  margin: auto;
}
.subscribe-content .newsletter-form {
  position: relative;
  width: 897px;
  margin: 30px auto auto;
  border-radius: 8px;
    border: 2px solid #f4f4f4;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    background-color: #ffffff;
}
.subscribe-content .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  border: none;
  background-color: #ffffff;
  color: #221638;
  height: 69px;
  /* padding: 6px 6px 6px 18px; */
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
}
.faq-accordion-tab
  .react-tabs__tab-list
  .react-tabs__tab.react-tabs__tab--selected,
.faq-accordion-tab .react-tabs__tab-list .react-tabs__tab:hover,
.pagination-area .page-numbers.current,
.pagination-area .page-numbers:hover {
  background-color: #ffc32b;
  color: #fff;
}
.subscribe-content .newsletter-form .validation-danger {
  color: red;
  margin-top: 15px;
}
.subscribe-content .newsletter-form .default-btn {
  position: relative;
  border-radius: 8px;
  height: 56px;
  width: 257px;
}
.checkout-area,
.faq-area {
  padding: 64px 0;
}
.checkout-area .checkout-area-content .empty-full {
  width: 100%;
  max-width: 773px;
  display: block;
  margin: 84px auto;
  color: #2c292a;
}
.checkout-area .checkout-area-content .empty-full h3 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 27px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.2px;
}
.checkout-area .checkout-area-content .empty-full p {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 30px;
}
.checkout-area .shopping-cart .cart-line-header {
  display: flex;
  padding-bottom: 23px;
  justify-content: space-between;
  border-bottom: 3px solid rgba(44, 41, 42, 0.05);
}
.checkout-area .cart-side-right .line-cart .value,
.checkout-area .shopping-cart .cart-line-header span {
  color: #2c292a;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  letter-spacing: 0.8px;
}
.checkout-area .cart-side-left {
  width: calc(100% - 452px);
  padding-right: 170px;
}
.checkout-area .cart-side-left .list .item:nth-child(3) {
  margin: 0 auto;
}
.checkout-area .cart-side-left .content .info .list strong,
.checkout-area .cart-side-left .content h3,
.checkout-area .cart-side-left .list .item .price,
.checkout-area .cart-side-right .line-cart.final .title {
  color: #2c292a;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1px;
}
.checkout-area .cart-side-left .shopping-cart-list {
  padding: 24px 0;
}
.checkout-area .cart-side-left .content .info .list li:first-child {
  margin-right: 24px;
  padding-right: 24px;
  border-right: 1px solid rgba(44, 41, 42, 0.15);
}
.checkout-area .cart-side-left .list .item .action-button button {
  background: 0 0;
  border: none;
  display: block;
  cursor: pointer;
}
.checkout-area .cart-side-left .list .item:first-child,
.footer-content .email-bottom .social-link li:not(:last-child) {
  margin-right: 24px;
}
.checkout-area .cart-side-left .list .image {
  border-radius: 8px;
  display: block;
  width: 140px;
  overflow: hidden;
  position: relative;
  padding-bottom: 140px;
}
.checkout-area .cart-side-left .list .image img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: auto;
  height: 100%;
  max-width: initial;
}
.checkout-area .cart-side-right {
  width: 452px;
  border-radius: 16px;
  padding: 24px;
  border: 2px solid rgba(44, 41, 42, 0.05);
  background: #f5f5f5;
}
.checkout-area .cart-side-right .line-cart {
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
  justify-content: space-between;
  align-items: center;
}
.checkout-area .cart-side-right .line-cart .title {
  color: #2c292a;
  font-size: 16px;
  font-weight: 300;
  line-height: 19.2px;
  letter-spacing: 0.8px;
}
.checkout-area .cart-side-right .line-cart.final .value {
  font-size: 40px;
  line-height: 48px;
}
.checkout-area .cart-side-right .line-cart .discount.value {
  color: rgba(44, 41, 42, 0.5);
}
@media (max-width: 1270px) {
  .checkout-area .cart-side-left {
    padding-right: 40px;
  }
}
@media (max-width: 1060px) {
  .checkout-area .cart-side-left {
    width: calc(100% - 350px);
  }
  .checkout-area .cart-side-right {
    width: 350px;
  }
}
@media (max-width: 440px) {
  .checkout-area .cart-side-right {
    padding: 24px 11px;
  }
  .checkout-area .default-btn {
    padding: 16px 0;
  }
  .checkout-area .cart-side-right .line-cart.final .value {
    font-size: 32px;
    line-height: 38.4px;
    letter-spacing: 1.6px;
  }
  .checkout-area .cart-side-right .line-cart .title {
    font-size: 18px;
    line-height: 21.6px;
  }
  .checkout-area .cart-side-left .content .info .list span,
  .checkout-area .cart-side-left .content .info .list strong {
    font-size: 16px;
    line-height: 22.4px;
  }
}
@media (max-width: 370px) {
  .checkout-area .cart-side-left .content .info .list li:first-child {
    margin-right: 16px;
    padding-right: 16px;
  }
}
.privacy-policy-content img,
.terms-of-service-content img {
  border-radius: 5px;
  display: block;
  width: 100%;
  margin-bottom: 30px;
}
.privacy-policy-content h3,
.terms-of-service-content h3 {
  margin-bottom: 15px;
  margin-top: 25px;
  font-size: 22px;
}
.privacy-policy-content .blockquote,
.privacy-policy-content blockquote,
.terms-of-service-content .blockquote,
.terms-of-service-content blockquote {
  margin-bottom: 30px;
  background-color: #e6f2f5;
  text-align: left !important;
  padding: 25px !important;
}
.privacy-policy-content .blockquote p,
.privacy-policy-content blockquote p,
.terms-of-service-content .blockquote p,
.terms-of-service-content blockquote p {
  margin-bottom: 0;
  line-height: 1.8;
  font-size: 16px !important;
  font-weight: 400;
}
.privacy-policy-content ol li,
.privacy-policy-content ul li,
.terms-of-service-content ol li,
.terms-of-service-content ul li {
  margin-bottom: 10px;
  color: #606060;
  font-size: 16px;
  line-height: 1.8;
}
.pagination-area .page-numbers {
  width: 33px;
  height: 33px;
  background-color: #f3f0f0;
  color: #606060;
  text-align: center;
  display: inline-block;
  border-radius: 3px;
  line-height: 34px;
  position: relative;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 16px;
  font-weight: 800;
}
.pagination-area .page-numbers i {
  position: relative;
  top: 1.5px;
}
.error-area {
  background-color: #2c292a;
  height: 100vh;
}
.error-content h3 {
  color: #fff;
  margin-bottom: 20px;
  margin-top: 45px;
  font-size: 40px;
}
.error-content p {
  color: rgba(255, 255, 255, 0.7);
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}
.error-content img {
  max-width: 450px;
  width: 100%;
}
.error-content .btn-box {
  margin: 30px auto auto;
  max-width: 500px;
}
.error-content .btn-box a:last-child:hover {
  text-decoration: none;
}
.faq-accordion-tab .react-tabs__tab-list {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 60px;
  text-align: center;
  border: none;
}
.faq-accordion-tab .react-tabs__tab-list .react-tabs__tab {
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
  color: #221638;
  text-decoration: none;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
  font-size: 16px;
  font-weight: 800;
}
.faq-accordion-tab .react-tabs__tab-list .react-tabs__tab i {
  font-size: 30px;
  font-weight: 400;
}
.faq-accordion-tab .react-tabs__tab-list .react-tabs__tab span {
  display: block;
  margin-top: 8px;
}
.faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  border: none;
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion__item {
  border-radius: 5px;
  display: block;
  box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
  background: #fff;
  margin-bottom: 15px;
  border: none;
}
.basic-info .content-information .item-form:last-child,
.contact-row .item:last-child,
.faq-accordion .accordion .accordion__item:last-child,
.login-form form .remember-me-wrap,
.register-form form .form-group:last-of-type {
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion__button {
  padding: 20px 25px;
  background-color: #fff;
  color: #221638;
  text-decoration: none;
  position: relative;
  display: block;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 800;
}
.faq-accordion .accordion .accordion__button i {
  position: absolute;
  left: 25px;
  top: 18px;
  font-size: 25px;
  transition: 0.5s;
}
@media (max-width:500px) {
  .about_formobileview{
    margin-top:0px ;
    padding-top: 0px;
   }
}
.faq-accordion .accordion .accordion__panel {
  position: relative;
  margin-top: -5px;
  padding: 0 30px 20px 48px;
}
.faq-accordion .accordion .accordion__panel p {
  line-height: 1.8;
}
.profile-block {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;
  position: relative;
  margin: 0px;
  width: 100%;
  max-width: 100%;
}
.welcome {
  font-size: 20px;
}
.login-register-block {
  max-width: 608px;
  padding-top: 9px;
  margin-top: 71px;
  position: relative;
  width: 100%;
  margin-left: 122px;
}
.login-register-block h2 {
  color: #2c292a;
  margin: 16px 0 10px;
  font-weight: 800;
  line-height: 48px;
  font-size: 40px;
}
.login-form {
  padding-right: 90px;
}
.logindesc{
  font-weight: 800;
  font-family: inter;
  font-size: 14px;
}
.register-form {
width: 1079px;
height: 100%;
overflow-x: hidden;
}


.login-lost-password,
.login-lost-password:hover {
  margin-left: 4px;
  font-weight: 700;
  text-decoration: underline;
}
.login-form form .form-group {
  margin-bottom: 25px;
}
.login-form form .form-group label,
.register-form form .form-group label {
  display: block;
  margin-bottom: 8px;
  color: #2c292a;
  font-weight: 700;
}
.login-form form .remember-me-wrap [type="checkbox"]:checked + label,
.login-form form .remember-me-wrap [type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  margin-bottom: 0;
  color: #606060;
  font-weight: 600;
}
.login-form form .remember-me-wrap [type="checkbox"]:checked + label:before,
.login-form
  form
  .remember-me-wrap
  [type="checkbox"]:not(:checked)
  + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 19px;
  height: 19px;
  transition: 0.2s;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  background: #f5f5f5;
}
.login-form form .remember-me-wrap [type="checkbox"]:checked + label:after,
.login-form
  form
  .remember-me-wrap
  [type="checkbox"]:not(:checked)
  + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #ffc32b;
  position: absolute;
  top: 5.5px;
  left: 6px;
  transition: 0.2s;
}
.login-form
  form
  .remember-me-wrap
  [type="checkbox"]:not(:checked)
  + label:after {
  opacity: 0;
  transform: scale(0);
}
.login-form form .remember-me-wrap [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.login-form form .remember-me-wrap a {
  color: #0000cd;
}
.login-form form .lost-your-password-wrap a {
  display: inline-block;
  position: relative;
  font-weight: 600;
  line-height: 1.3;
}
.login-form form .lost-your-password-wrap a::before {
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  transition: 0.5s;
  background-color: #eee;
}
.login-form form .lost-your-password-wrap a::after {
  width: 0;
  height: 1px;
  position: absolute;
  left: 0;
  transition: 0.5s;
  bottom: 0;
  content: "";
  background-color: #ffc32b;
}
.login-form form .lost-your-password-wrap a:hover::before {
  width: 0;
}
.login-receive-email {
  margin-top: -15px;
}
.login-didnt-receive-email {
  color: #2c292a;
}
.login-lost-password {
  color: #fbb723;
  transition: 0.2s ease-in;
}
.login-lost-password:hover {
  color: #221638;
}
.login-form form button {
  border: none;
  display: block;
  text-align: center;
  overflow: hidden;
  color: #2c292a;
  background-color: #fbb723;
  transition: 0.5s;
  width: 100%;
  border-radius: 8px;
  padding: 14.5px 30px;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.login-form form button:hover,
.register-form form button:hover {
  background-color: #2c292a;
  color: #fbb723;
}
.login-forgot-password {
  display: flex;
  margin-top: 32px;
  justify-content: center;
}
.register-form form .form-group {
  margin-bottom: 35px;
}
.register-form form .description {
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 64px;
}
.register-form form button {
  border: none;
  display: block;
  text-align: center;
  overflow: hidden;
  color: #2c292a;
  background-color: #fbb723;
  transition: 0.5s;
  width: 100%;
  border-radius: 8px;
  padding: 14.5px 30px;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
@media (max-width: 1280px) {
  .login-form-container {
    padding-right: 20px;
  }
  .register-form {
    margin-left: 30px;
    padding-left: 25px;
  }
}
@media (max-width: 1400px){
  .register-form{
    display: none;
  }
  .login-form{
    margin: auto;
  }
  
}
@media (max-width: 1152px) {
  .login-forgot-password {
    flex-direction: column;
    align-items: center;
  }
}
.teacher-register-area,
.work-with-us-area {
  padding: 64px 0 169px;
}
.contact-area-content .left-side,
.teacher-content .left-side,
.work-content .left-side {
  width: calc(100% - 608px);
  padding-right: 51px;
}
.teacher-content .right-side,
.work-content .right-side {
  width: 608px;
}
.contact-info .sub-title,
.instructor-info .sub-title,
.work-info .sub-title {
  color: #fbb723;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 1px;
  display: block;
  margin-bottom: 16px;
}
.contact-content h2,
.contact-info h2,
.instructor-info h2,
.work-info h2 {
  color: #2c292a;
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  letter-spacing: 2px;
  margin-bottom: 16px;
}
.contact-info p,
.instructor-info p,
.work-info p {
  color: #2c292a;
  font-size: 20px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 1px;
  display: block;
  margin: 16px 0 8px;
}
.contact-form h2,
.contact-info h3 {
  font-size: 24px;
  letter-spacing: 1.2px;
}
.instructor-info .line-work,
.work-info .line-work {
  display: flex;
  flex-direction: row;
  padding-top: 55px;
}
.instructor-info .line-work li:not(:last-child),
.work-info .line-work li:not(:last-child) {
  padding-right: 20px;
  margin-right: 20px;
  border-right: 3px solid rgba(44, 41, 42, 0.05);
}
.instructor-info .line-work li strong,
.work-info .line-work li strong {
  color: #fbb723;
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  letter-spacing: 2px;
  display: block;
}
.instructor-info .line-work li span,
.work-info .line-work li span {
  color: #2c292a;
  font-size: 18px;
  font-weight: 600;
  line-height: 28.8px;
  letter-spacing: 0.9px;
}
.contact-info h3 {
  color: #2c292a;
  font-weight: 800;
  line-height: 28.8px;
  margin-bottom: 0;
}
.contact-content p.last,
.contact-content ul {
  margin-bottom: 120px;
}
.contact-content ul {
  padding-left: 28px;
}
.contact-content ul li strong {
  font-weight: 700;
}
.contact-content ul li {
  margin-bottom: 34px;
  list-style-type: disc;
}
.contact-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.contact-row .item {
  margin-bottom: 32px;
  width: 100%;
}
.contact-row .item:nth-child(2) {
  width: 50%;
  padding-right: 9px;
}
.contact-row .item:nth-child(3) {
  width: 50%;
  padding-left: 9px;
}

.contact-row .item label {
  display: block;
  color: #2c292a;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.contact-info .line-icons-contact {
  display: flex;
  flex-direction: row;
  margin-top: 41px;
}
.contact-info .line-icons-contact li,
.contact-info .line-icons-contact li p {
  color: #2c292a;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 25.6px;
  letter-spacing: 0.8px;
}
.contact-info .line-icons-contact li:first-child {
  padding-right: 11px;
}
.contact-info .line-icons-contact li:nth-child(2) {
  max-width: 321px;
  padding-right: 0;
}
.contact-info .line-icons-contact li span {
  font-weight: 600;
}
.contact-form {
  border-radius: 16px;
  border: 2px solid rgba(44, 41, 42, 0.05);
  background: #f5f5f5;
  width: 100%;
  padding: 30px 22px;
}
.footer-area{
margin-top: 30px;
}
.footer-area,
.go-top {
  background-color: #fff;
}
.contact-form h2 {
  color: #2c292a;
  font-weight: 800;
  line-height: 28.8px;
  margin-bottom: 6px;
}
.contact-form p {
  color: #2c292a;
  font-size: 20px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 1px;
  margin-bottom: 26px;
  display: block;
}
.contact-form form input,
.contact-form form textarea {
  display: block;
  width: 100%;
  height: 56px;
  padding-left: 16px;
  color: #868686;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 8px;
  border: 2px solid rgba(44, 41, 42, 0.25);
  background: #fff;
  transition: 0.2s ease-in;
}
.contact-form form input:focus,
.contact-form form textarea:focus {
  border-color: #2c292a;
}
.contact-form form textarea {
  height: auto !important;
  padding-top: 15px;
}
.contact-form form .default-btn {
  border-radius: 8px;
  background: #fbb723;
  text-align: center;
  color: #2c292a;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.32px;
  width: 100%;
  height: 56px;
  padding: 16px 0;
}
#map iframe {
  height: 500px;
  margin-bottom: -7px;
  width: 100%;
  border: none;
}
@media (max-width: 1200px) {
  .contact-area-content .left-side,
  .teacher-content .left-side,
  .work-content .left-side {
    width: calc(100% - 500px);
    padding-right: 15px;
  }
}
.footer-area {
  position: relative;
  z-index: 1;
 
}

.footer-content .title-bottom {
  color: #2c292a;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 12px;
  display: block;
  line-height: 18.9px;
  letter-spacing: -0.18px;
}
@media (max-width: 1080px) {
  .footer-content .title-bottom{
   text-align: center;
  }
  .footer-content .item:nth-child(2){
    margin-top: 40px;
    max-width: 60% !important;
  }
  .footer-content{
    justify-content: space-between;
  }
  .footer-content .item:first-child{
    padding-top: 0px !important;
  }
}

.footer-content .email-bottom p {
  max-width: 276px;
  display: block;
}
.footer-content .email-bottom .social-link {
  margin: 0px 0 17px;
}
.footer-content .email-bottom .social-link li a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fbb723;
  color: #2c292a;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  transition: 0.2s ease-in;
}
.footer-content .payments li {
  margin-right: 13px;
}
.footer-content .item {
  padding: 0 40px;
}

.footer-content .item:first-child {
  padding-left: 0;
  max-width: 331px;
}
.footer-content .item:nth-child(2) {
  max-width: 411px;
  padding-top: 40px;
}
.footer-content .item:nth-child(3) {
  padding-top: 35px;
}
.footer-content .item:nth-child(2) .logo {
  display: block;
  margin-bottom: 24px;
}
@media (max-width: 1080px) {
  .footer-content .item:nth-child(2) .logo {
    display: block;
    margin-bottom: 24px;
    text-align: -webkit-center;
  }
}

.footer-content .item p {
  text-align: left;
  color: #2c292a;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: -0.16px;
  margin: 0;
}
.footer-content .item:last-child {
  padding-right: 0;
  padding-top: 63px;
}
.footer-content{
  margin-bottom: 20px;
}
.logo-footer{
  width: 228px;
}
.footer-content .item .seal {
  margin-bottom: 17px;
  width: 121px;
  margin: auto;
}
.footer-content .item .seal + span {
  color: #2c292a;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 25.6px;
  letter-spacing: 0.8px;
  display: block;
}
.footer-content .item .seal img {
  display: block;
  margin: auto;
}
.footer-bottom {
  border-top: 3px solid rgba(44, 41, 42, 0.05);
  padding: 16px 0;
  align-items: center;
}
.footer-bottom a:hover {
  color: #000;
  text-decoration: underline;
}
.footer-bottom p {
  font-size: 12px;
  color: rgba(44, 41, 42, 0.6);
  font-weight: 400;
  line-height: 19.2px;
  letter-spacing: -0.12px;
  margin: 0;
}
.footer-bottom .right-block ul li {
  margin: 0 16px;
}
.footer-bottom .right-block ul li a {
  color: #2c292a;
  font-weight: 600;
}
.footer-bottom .right-block ul li a:hover {
  text-decoration: underline;
}
.get-certificate .content,
.go-top i {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  text-align: center;
  left: 0;
}
@keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 15px;
  right: 15px;
  color: #221638;
  z-index: 999;
  width: 45px;
  text-align: center;
  height: 45px;
  border-radius: 50%;
  font-size: 40px;
  transition: 0.9s;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}
.flag-button,
.quiz-container button,
.quiz-container ul li {
  transition: 0.2s ease-in;
  cursor: pointer;
}
.selected_flag
{

  background-color: #fbb723  !important;
  color: #2c292a !important;
  
}

.go-top i {
  top: 70%;
  transform: translateY(-70%);
}
.go-top:hover {
  background-color: #ffc32b;
  color: #fff;
  transition: 0.5s;
  transform: translateY(-10px);
}
.modal {
  display: block;
  opacity: 1;
}
.modal .modal-dialog {
  max-width: 720px;
}
.modal .modal-content {
  display: block;
  border: none;
  padding: 0;
  background-color: #fff;
  flex-direction: unset;
  border-radius: 0;
}
.modal .btn-close {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  width: 100%;
  cursor: zoom-out !important;
  height: 100%;
  border-radius: 0;
  padding: 0;
  box-shadow: unset;
  color: #fff;
  background-image: unset;
  font-size: 30px;
}
.modal-backdrop {
  background: rgba(18, 18, 18, 0.7) !important;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
@media only screen and (max-width: 767px) {
  .modal .modal-dialog {
    max-width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal .modal-dialog {
    max-width: 700px;
  }
}
.get-certificate .content {
  top: 52%;
  max-width: 350px;
  transform: translateY(-52%);
}
.get-certificate .caption {
  text-align: right;
  margin-top: -50px;
  position: relative;
  padding-right: 10px;
  opacity: 0;
  transition: 0.5s;
  z-index: 2;
}
.quiz-container {
  background: #fff;
  border-radius: 4px;
}
.quiz-container .active-question-no {
  font-size: 32px;
  font-weight: 500;
  color: #ffc32b;
}
.quiz-container .total-question {
  font-size: 16px;
  font-weight: 500;
  color: #e3e1de;
}
.quiz-container h2 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.quiz-container p {
  margin-bottom: 0;
  color: #2c292a;
  letter-spacing: 0.9px;
  line-height: 22.8px;
}
.quiz-container ul li {
  text-decoration: none;
  list-style: none;
  font-size: 16px;
  border-radius: 16px;
  padding: 15px 14px;
  margin-top: 15px;
  background-color: #f5f5f5;
  color: #2c292a;
  border: 1px solid rgba(44, 41, 42, 0.05);
}
.quiz-container ul .selected-answer.correct {
  border: 3px solid #ffc32b;
}
.quiz-container button {
  background: #ffc32b;
  border-radius: 9px;
  font-size: 18px;
  color: #2c292a;
  padding: 10px 42px;
  font-weight: 600;
  outline: 0;
  border: none;
  margin-top: 20px;
}
.quiz-container .test-result-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 28.8px;
}
.quiz-container button:disabled {
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}
.flex-right {
  display: flex;
  justify-content: flex-end;
}
.result h3 {
  font-size: 24px;
  letter-spacing: 1.4px;
  text-align: center;
}
.result p {
  font-size: 16px;
  font-weight: 500;
}
.result p span {
  color: #ffc32b;
  font-size: 22px;
}
.get-certificate .caption .download-btn {
  width: 40px;
  height: 40px;
  line-height: 42px;
  background-color: #fff;
  border: 0;
  padding: 0;
  font-size: 40px;
  border-radius: 5px;
  color: #f44336;
}
.get-certificate .caption .download-btn:first-child {
  color: #3100f3;
}
.get-certificate .col-lg-8:hover .caption {
  opacity: 1;
}
.live-class-icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  z-index: 1;
  background-color: red;
  border-radius: 50%;
  position: relative;
  top: 4px;
}
.sliderforcomapny .coverofsponer4{
  top: 0;
}
@media (max-width:1440px) {
  .footer-area {
    margin-top: 0px;
  }
 
  .homereview{
max-width: 1121px;
  }
  .callphone-block .left-side{
    top: -119px;
    left: -40px;
  }
  .footer-content .item:last-child {
    padding-right: 0;
    padding-top: 42px;
    padding-left: 23px;
}

.cards {
    width: 100%;
    margin-top: 33px;
}
.footer-content .item:nth-child(3) {
  padding-top: 10px;
}
.covroflinks{
  margin-top: 40px;
}
  .footer-area .footer-content {
    padding-top: 30px;
   max-width: 1200px;
   margin: auto;
  }
  .footer-content .item:nth-child(2){
    width: 531px;
    padding: 20px;
  }
  .footer-content .item{
    padding: 0px;
  }
}
.live-class-icon::after,
.live-class-icon::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  transition: 0.5s;
  border-radius: 50%;
  border: 1px solid red;
}
img.emoji,
img.wp-smiley,
span.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: 0 0 !important;
  padding: 0 !important;
}
span.emoji {
  color: #000 !important;
}
:where(.is-layout-flex) {
  gap: 0.5em;
}
body .is-layout-constrained > .alignleft,
body .is-layout-flow > .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}
body .is-layout-constrained > .alignright,
body .is-layout-flow > .alignright {
  float: right;
  margin-inline-start: 2em;
  margin-inline-end: 0;
}
body .is-layout-constrained > .aligncenter,
body .is-layout-flow > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}
body
  .is-layout-constrained
  > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
  max-width: var(--wp--style--global--content-size);
  margin-left: auto !important;
  margin-right: auto !important;
}
body .is-layout-constrained > .alignwide {
  max-width: var(--wp--style--global--wide-size);
}
body .is-layout-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.has-black-color {
  color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-color {
  color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-color {
  color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-color {
  color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-color {
  color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-color {
  color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-color {
  color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-color {
  color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-color {
  color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-color {
  color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-color {
  color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-color {
  color: var(--wp--preset--color--vivid-purple) !important;
}
.has-black-background-color {
  background-color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-background-color {
  background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-background-color {
  background-color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-background-color {
  background-color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-background-color {
  background-color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-background-color {
  background-color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-background-color {
  background-color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-background-color {
  background-color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-background-color {
  background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-background-color {
  background-color: var(--wp--preset--color--vivid-purple) !important;
}
.has-black-border-color {
  border-color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-border-color {
  border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-border-color {
  border-color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-border-color {
  border-color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-border-color {
  border-color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-border-color {
  border-color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-border-color {
  border-color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-border-color {
  border-color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-border-color {
  border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-border-color {
  border-color: var(--wp--preset--color--vivid-purple) !important;
}
.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
  background: var(
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple
  ) !important;
}
.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
  background: var(
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan
  ) !important;
}
.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
  background: var(
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange
  ) !important;
}
.has-luminous-vivid-orange-to-vivid-red-gradient-background {
  background: var(
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red
  ) !important;
}
.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
  background: var(
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray
  ) !important;
}
.has-cool-to-warm-spectrum-gradient-background {
  background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
}
.has-blush-light-purple-gradient-background {
  background: var(--wp--preset--gradient--blush-light-purple) !important;
}
.has-blush-bordeaux-gradient-background {
  background: var(--wp--preset--gradient--blush-bordeaux) !important;
}
.has-luminous-dusk-gradient-background {
  background: var(--wp--preset--gradient--luminous-dusk) !important;
}
.has-pale-ocean-gradient-background {
  background: var(--wp--preset--gradient--pale-ocean) !important;
}
.has-electric-grass-gradient-background {
  background: var(--wp--preset--gradient--electric-grass) !important;
}
.has-midnight-gradient-background {
  background: var(--wp--preset--gradient--midnight) !important;
}
.has-small-font-size {
  font-size: var(--wp--preset--font-size--small) !important;
}
.has-medium-font-size {
  font-size: var(--wp--preset--font-size--medium) !important;
}
.has-large-font-size {
  font-size: var(--wp--preset--font-size--large) !important;
}
.has-x-large-font-size {
  font-size: var(--wp--preset--font-size--x-large) !important;
}
:where(.wp-block-columns.is-layout-flex) {
  gap: 2em;
}
.wp-block-pullquote {
  font-size: 1.5em;
  line-height: 1.6;
}
.wp-block-navigation a:where(:not(.wp-element-button)) {
  color: inherit;
}
:root {
  --font-headings: unset;
  --font-base: unset;
  --font-headings-default: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-base-default: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
#file-name,
#pdf-download,
.cmplz-hidden {
  display: none !important;
}
.message-bubble {
  font-family: Plus Jakarta Sans Sans, sans-serif;
  display: flex;
  flex-flow: row wrap;
  position: fixed;
  background: #fff;
  color: #00000f;
  right: calc(100px + 2%);
  max-width: 240px;
  bottom: 60px;
  padding: 1em 1em 12px 12px;
  border-radius: 13px 13px 2px;
  box-shadow: 1px -1px 9px rgba(0, 0, 0, 0.75);
  z-index: 980;
  animation: 0.5s ease-out toggle-bubble;
  margin-left: 15px;
  line-height: 1.5em;
  transform-origin: 100% 100%;
}
.message-bubble::after {
  bottom: 0;
  content: "";
  position: absolute;
  left: calc(100% - 15px);
  width: 0;
  border: 14px solid transparent;
  border-bottom-color: #fff;
  border-radius: 4.5px;
}
.message-bubble::before {
  bottom: 0;
  content: "";
  position: absolute;
  left: calc(100% - 12px);
  width: 0;
  border: 15px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  filter: blur(2px);
  border-radius: 4.5px;
}
.message-bubble p + p {
  margin-block: 0.4em 0;
}
.message-bubble .close-button {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 0.7em;
  height: 0.7em;
  opacity: 0.3;
}
.message-bubble .close-button:hover {
  opacity: 1;
  cursor: pointer;
}
.message-bubble .close-button:after,
.message-bubble .close-button:before {
  position: absolute;
  content: " ";
  height: 0.7999999999999999em;
  width: 2px;
  background-color: #333;
}
.message-bubble .close-button:after {
  transform: rotate(-45deg);
}
@-moz-keyframes toggle-bubble {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}
@-webkit-keyframes toggle-bubble {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}
@-o-keyframes toggle-bubble {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}
@keyframes toggle-bubble {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}
#blip-chat-container #blip-chat-iframe {
  position: initial !important;
}
.tab-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 0 10px;
  align-items: center;
  background: #ffc32b;
  cursor: pointer;
  box-shadow: #00000089 0 5px 5px 0;
  border: none;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: #000;
}
.courses-filter,
.flag-icon {
  display: flex;
  align-items: center;
}
.courses-filter {
  margin-bottom: 16px;
}
.flag-button {
  border-radius: 12px;
  background: white;
  height: 35px;
 
}
.flag-button img {
  max-width: 25px;
  display: block;
  width: 100%;
}

.flag-button span {
  padding-left: 8px;
}
.flag-button.active {
  background-color: #fbb723;
  color: #2c292a;
}
.flag-icon {
  width: 34px;
  height: 34px;
  margin-right: 0;
  border: 1.5px solid #fff;
  box-shadow: 0 2px 2px 0 #00000089;
  border-radius: 50%;
  background-size: cover;
}
.flag-icon-banner {
  display: inline-flex;
  align-content: flex-start;
  width: 55px;
  height: 55px;
  border: 7.5px solid #2c292a;
  border-radius: 50%;
  background-size: cover;
}
.active-tab,
.selo {
  height: 100%;
  z-index: 1;
}
.controller{
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.controller input{
  border: none;
  width: 80%;
}
.controller input:focus{
  outline: none;
}
span.flag-icon-banner {
  border: 7.5px solid #2c292a !important;
  box-sizing: border-box !important;
  border-radius: 50% !important;
  width: 55px !important;
  height: 55px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  display: inline-flex !important;
  align-content: flex-start !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
}
.account-header li a,
.line-filters > span {
  color: #2c292a;
  font-size: 20px;
  letter-spacing: 1px;
}
.flag-icon-es {
  background-image: url("https://cdn.jsdelivr.net/gh/stevenrskelton/flag-icon@0.11.1/svg/country-squared/es.svg");
}
.flag-icon-us {
  background-image: url("https://cdn.jsdelivr.net/gh/stevenrskelton/flag-icon@0.11.1/svg/country-squared/us.svg");
}
.flag-icon-arab {
  background-image: url("https://cdn.jsdelivr.net/gh/stevenrskelton/flag-icon@0.11.1/svg/country-squared/sd.svg");
}
.flag-icon-ru {
  background-image: url("https://cdn.jsdelivr.net/gh/stevenrskelton/flag-icon@0.11.1/svg/country-squared/ru.svg");
}
.flag-icon-hindi {
  background-image: url("https://cdn.jsdelivr.net/gh/stevenrskelton/flag-icon@0.11.1/svg/country-squared/in.svg");
}
.flag-icon-fr {
  background-image: url("https://cdn.jsdelivr.net/gh/stevenrskelton/flag-icon@0.11.1/svg/country-squared/fr.svg");
}
.flag-icon-pt {
  background-image: url("https://cdn.jsdelivr.net/gh/stevenrskelton/flag-icon@0.11.1/svg/country-squared/br.svg");
}
.flag-icon-ur {
  background-image: url("https://cdn.jsdelivr.net/gh/stevenrskelton/flag-icon@0.11.1/svg/country-squared/pk.svg");
}
.flag-icon-all {
  /* background-image: url("/content/uploads/2023/03/flags/all.png"); */
  align-content: center;
}
.active-tab {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0 10px;
  align-items: center;
  background: #000;
  cursor: pointer;
  box-shadow: #00000089 0 5px 5px 0;
  border: none;
  text-align: center;
  overflow: hidden;
  color: #fff !important;
  animation: 1s infinite alternate pulse;
}
.lang,
.line-filters {
  flex-direction: row;
  display: flex;
}
.lang {
  width: 100%;
  margin: 1.5rem 0 0 1rem;
  flex-wrap: nowrap;
  justify-content: flex-start;
  text-transform: uppercase;
  align-items: flex-start;
  font-size: smaller;
  font-style: oblique;
  font-weight: 700;
}
#navbar {
  z-index: 10;
}
div#pdf-renderer {
  background-color: #fffaf0;
  border: 1.5px solid #ffc32b;
}
.selo {
  flex-shrink: 0;
  position: absolute;
  left: 25%;
  bottom: -70%;
}
@media (max-width: 1420px) {
  .footer-content .email-bottom {
    padding-right: 50px;
  }
  .flag-button {
    margin-right: 10px;
    padding-right: 0;
  }
}
.line-filters {
  align-items: center;
  margin-bottom: 64px;
}
.line-filters .item-filter {
  max-width: 196px;
}
.line-filters > span {
  display: block;
  font-weight: 700;
  line-height: 24px;
  margin-right: 32px;
}
.line-filters .item-filter select {
  border: 2px solid #2c292a;
  color: #2c292a;
  font-weight: 600;
  border-radius: 50px;
}
.courses-area-list {
  display: flex;
  flex-direction: row;
  padding-bottom: 120px;
}
.courses-area-list .courses-item {
  width: calc(100% / 3);
  margin-right: 16px;
  overflow: hidden;
  border-radius: 8px;
}
.courses-area-list .courses-item .courses-block {
  background: #f5f5f5;
  border-radius: 0 0 8px 8px;
}
.courses-area-list .courses-item .img img {
  display: block;
  border-radius: 8px 8px 0 0;
}
.courses-area-list .courses-item .truck {
  position: absolute;
  right: 24px;
  bottom: -24px;
  border-radius: 50%;
  width: 58px;
  height: 58px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.courses-area-list .courses-item .item-course-block {
  padding: 24px;
}
.courses-item .title-block {
  font-size: 24px;
  color: #2c292a;
  font-weight: 700;
  line-height: 28.8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.courses-item .title-block img {
  margin-left: 16px;
  display: block;
  position: relative;
  top: -1px;
}
.contact-area-content .right-side{
  width: 1240px;
  margin: auto;
}
.coverofhome_company .Graudatestudentjobs{
  border: none;
  background-color: #fff;

}
.coverofhome_company{
  padding-bottom: 70px;
}
@keyframes switch-animation {
  0% {
      opacity: 1;
  }

  /* Initial state - SVG1 fully visible */
  50% {
      opacity: 0;
  }

  /* Halfway point - SVG1 fades out, SVG2 fades in */
  100% {
      opacity: 1;
  }

  /* Final state - SVG2 fully visible */
}

.animation-container_about {
  position: relative;
  max-width: 35vw;
  left: 46%;
  z-index: 9999;
  top: 190px;
}

.svg1_about,
.svg2_about {
  position: absolute;
  top: 0;
  left: 11px;
  opacity: 0;
  /* Initially hidden */
}

.svg1_about {
  animation: switch-animation 2s infinite;
  /* Adjust animation duration as needed */
}

.svg2_about {
  animation: switch-animation 2s infinite alternate;
  /* Adjust animation duration as needed */
  animation-delay: 1s;
  /* Start after 1 second to create alternating effect */
}

.learntext_about {
color: #fff;
font-weight: 600;
font-family: Inter, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
position: relative;
top: 80px;
font-size: 21px;

}
.successsent{
  color: #0A0A0A;
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 160% */
justify-content: center;
align-items: center;
padding-top: 150px;
padding-bottom: 150px;
}
@media (max-width: 1442px) {
  .contact-area-content .right-side{
    width: 1240px;
    margin: auto;
  }
 

}
@media (max-width: 1380px) {
  .courses-main .courses-block {
    padding: 46px 80px 46px 100px;
  }
  .courses-main .courses-block .buy-now {
    max-width: 220px;
  }
}
@media (max-width: 1300px) {
  .contact-area-content .left-side,
  .teacher-content .left-side,
  .work-content .left-side {
    width: calc(100% - 500px);
  }
  .teacher-content .right-side,
  .work-content .right-side {
    width: 500px;
  }
  .footer-content .item {
    padding: 0 24px;
  }
  .footer-bottom .right-block ul li {
    margin: 0 10px;
  }
  .courses-main .courses-block .left-block {
    max-width: 380px;
  }
  .flag-button {
    font-size: 14px;
    margin-right: 6px;
    overflow: hidden;
  }
  .flag-button span {
    padding-left: 5px;
  }
}
@media (max-width: 1140px) {
  .courses-main .courses-block {
    padding: 46px 20px 46px 100px;
  }
  .courses-main .courses-block .left-block {
    max-width: 380px;
  }
}
@media (max-width: 1160px) {
  .courses-item .courses-block .line-more-info,
  .instructor-info .line-work,
  .work-info .line-work {
    flex-direction: column;
  }
  .instructor-info .line-work li:not(:last-child),
  .work-info .line-work li:not(:last-child) {
    border-right: none;
    margin: 0 0 23px;
    padding: 0 0 23px;
    border-bottom: 3px solid rgba(44, 41, 42, 0.05);
  }
  .courses-item .courses-block .line-more-info .item:first-child {
    padding-top: 0;
  }
  .courses-item .courses-block .line-more-info .item {
    padding: 16px 0;
    border: none;
    width: 100%;
  }
  .courses-item .courses-block .line-more-info .item:not(:last-child) {
    border-bottom: 2px solid rgba(44, 41, 42, 0.15);
  }
}

@media (max-width: 990px) {
  .subscribe-content .newsletter-form{
    width: 80%;
  }

  .css-fph0cl{
padding-bottom: 0px !important;
  }
  .coverofbannerimage{
    display: none;
  }
  .callphone-block {
    flex-direction: column;
    background: url("https://res.cloudinary.com/dezhgxayn/image/upload/v1706876956/Group_1171278619_ltoubh.png") center/cover
      no-repeat;
    max-width: 550px;
    padding: 27px 20px 0;
    border-radius: 16px;
  }
  .callphone-block .left-side {
    order: 2;
    position: relative;
    max-width: 100%;
    left: -13px;
    top: 25px;
  }
  .call-to-phone-block{
    margin-top: 318px;
  }

  .callphone-block .button-block,
  .feedback-content,
  .footer-bottom,
  .get-instant-block,
  .video-area .video-area-block {
    flex-direction: column;
    margin: auto;
  }
  .callphone-block .button-block button {
    margin: 0 0 20px;
    width: 100%;
  }
  .callphone-block .button-block button:last-child {
    margin: 0;
  }
  .callphone-block .right-side {
    order: 1;
    padding: 0;
    margin: auto auto 67px;
    max-width: initial;
    min-width: initial;
  }
  .feedback-area{
    height: fit-content;
  }
  .feedback-area .left-block {
    width: 100%;
    padding: 0;
    text-align: left;
    /* padding-top: 80px !important; */
  }
  .feedback-content{
    padding-left: 0px;
    padding-right: 0px;
  }
  .feedback-area .right-block {
    width: 100%;
    max-width: initial;
    margin: 65px 0 35px;
  }
  .feedback-content .title {
    max-width: initial;

  }
  .feedback-area {
    padding: 74px 0 0;
  }
  .single-feedback-item .background-white {
    min-height: initial;
  }
  .checkout-area .cart-side-left .list .item .mobile,
  .content-details-course.mobile,
  .course-mobile,
  .courses-main .prices.mobile,
  .video-content .pdf-viewer.mobile {
    display: block;
  }
  .checkout-area .cart-side-left .list .item .desktop,
  .content-details-course.desktop,
  .course-desktop,
  .courses-main .prices.desktop,
  .video-content .pdf-viewer.desktop {
    display: none;
  }
  .video-area {
    padding: 0 0 85px;
  }
  .video-area .right-side > div {
    top: 0 !important;
    position: initial !important;
  }
  .video-area .right-side {
    width: 100%;
    margin-top: 32px;
  }
  .video-content .pdf-viewer embed,
  .video-content .pdf-viewer iframe,
  .video-content .pdf-viewer object {
    border: none;
    border-radius: 0;
  }
  .contact-area-content .left-side,
  .teacher-content .left-side,
  .video-area .left-side,
  .work-content .left-side {
    width: 100%;
    padding-right: 0;
  }
  .video-area .video-content #react-doc-viewer {
    border-radius: 0;
    margin: 0 -33px;
    width: 100vw;
  }
  .get-instant-courses-image {
    order: 1;
    width: 100%;
    max-width: 450px;
    margin: 0 auto 42px -7px;
  }
  .get-instant-courses-content {
    order: 2;
    width: 100%;
    padding-right: 0;
  }
  .get-instant {
    padding: 78px 0;
    overflow: hidden;
  }
  .page-title-content h2 {
    text-align: left;
  }
  .page-title-content {
    font-size: 32px;
    margin: 0 !important;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
  }
  .page-title-content ul {
    margin: 0;
    position: initial;
    text-align: left;
    order: 2;
    height: initial;
  }
  .page-title-area {
    padding: 80px 0 40px;
    margin-bottom: 0;
  }
  .subscribe-content,
  .subscribe-content p {
    text-align: left;
    margin: 0;
  }
  .checkout-area .cart-side-left {
    width: 100%;
    margin-bottom: 30px;
    padding-right: 0;
  }
  .checkout-area .cart-side-right {
    width: 100%;
  }
  .checkout-area .checkout-area-content {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .checkout-area .cart-side-left .list,
  .courses-area-list {
    flex-wrap: wrap;
    flex-direction: initial;
  }
  .checkout-area .cart-side-left .list .action-button {
    display: flex;
    align-items: center;
    color: #2c292a;
    font-size: 19px;
    font-weight: 700;
    line-height: 22.8px;
    letter-spacing: 0.95px;
  }
  .checkout-area .cart-side-left .list .item {
    margin: 0 !important;
  }
  .checkout-area .cart-side-left .list .title-block {
    text-align: right;
  }
  .checkout-area .cart-side-left .content .info .list {
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
  .checkout-area .cart-side-left .list .item:first-child,
  .checkout-area .cart-side-left .list .item:nth-child(2) {
    width: 50%;
  }
  .checkout-area .cart-side-left .list .item:nth-child(3) {
    width: 100%;
    padding: 25px 0;
  }
  .register-form form .description,
  .register-form form .form-group {
    margin-bottom: 29px;
  }
  .profile-block {
    align-items: center;
    flex-direction: column;
    max-width: 93%;
    padding: 0 0 92px;
    margin: auto;
  }
  .login-form {
    padding: 0 0 59px;
    max-width: 100%;
  }
  .login-register-block h2 {
    font-size: 32px;
    line-height: initial;
    margin: 16px 0 29px;
  }
  .login-form:after {
    top: initial;
    left: 0;
    right: 0;
    bottom: -8px;
    width: 65px;
    height: 26px;
    padding: 0 20px;
  }
 
  .login-form-container {
    padding: 0;
    max-width: 100%;
  }
  .register-form {
    max-width: 100%;
    margin-top: 44px;
    margin-left: 0;
    padding-left: 0;
  }
  .instructor-info .line-work,
  .work-info .line-work {
    padding: 40px 0;
  }
  .teacher-register-area,
  .work-with-us-area {
    padding: 64px 0;
  }
  .contact-area .contact-area-content,
  .teacher-register-area .teacher-content,
  .work-with-us-area .work-content {
    flex-direction: column;
    padding-bottom: 0;
  }
  .contact-area-content .right-side,
  .teacher-content .right-side,
  .work-content .right-side {
    width: 100%;
    padding-bottom: 64px;
  }
  .footer-content .email-bottom{
    padding-top: 40px;
  }
  .footer-content .item:last-child,
  .teacher-content .right-side,
  .work-content .right-side {
    padding: 0;
  }
  .contact-info .line-icons-contact {
    margin-top: 24px;
    flex-direction: column;
  }
  .contact-info .line-icons-contact li {
    margin-bottom: 24px;
  }
  .footer-content .item,
  .footer-content .item p {
    margin: auto;
    text-align: center;
  }
  .courses-main .courses-block .buy-now,
  .footer-content img {
    max-width: 100%;
  }
  .footer-content .item {
    padding: 0 0 52px;
  }
  .footer-content .email-bottom .social-link {
    margin: 36px auto 49px;
    justify-content: center;
  }
  .footer-content .email-bottom .social-link li,
  .footer-content .email-bottom .social-link li:not(:last-child) {
    margin: 0 12px;
  }
  .footer-content .email-bottom .social-link li:first-child {
    margin-left: 0;
  }
  .courses-area-list .courses-item:nth-child(2n) .single-courses-box,
  .footer-content .email-bottom .social-link li:last-child {
    margin-right: 0;
  }
  .ref-bottom .where-to-find {
    padding-bottom: 52px;
  }
  .footer-content,
  .footer-content .ref-bottom {
    flex-direction: column;
    justify-content: center;
  }
  .footer-content .payments {
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer-content .payments li,
  .footer-content .payments li:last-child {
    margin: 13px 13px 6.5px;
  }
  .footer-bottom .right-block ul {
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer-bottom .right-block ul li {
    margin: 0 12px 24px;
  }
  .footer-bottom .right-block {
    order: 1;
    margin: auto auto 20px;
  }
  .footer-bottom p {
    order: 2;
    text-align: center;
    margin: auto auto 30px;
    padding: 0 20px;
  }
  .courses-area-list .courses-item {
    width: calc(100% / 2);
    margin: 0 0 20px;
  }
  .courses-area-list .courses-item .single-courses-box {
    margin-right: 16px;
  }
  .courses-filter {
    flex-direction: column;
    max-width: 300px;
    margin: auto;
  }
  .flag-button:before {
    content: "";
    background: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.75 12H20.25" stroke="%23262526" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/><path d="M12 3.75V20.25" stroke="%23262526" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/></svg>')
      center/24px no-repeat;
    position: absolute;
    right: 10px;
    display: block;
    margin: auto;
    width: 24px;
    height: 24px;
    top: 0;
    transition: 0.2s ease-in;
    z-index: 2;
    bottom: 0;
  }
  .flag-button.active:before {
    transform: rotate(45deg);
  }
  .flag-button {
    position: relative;
    width: 100%;
    line-height: 25.6px;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 16px;
    background: none;
  }
  .courses-main .courses-block {
    flex-direction: column;
    max-width: 600px;
    margin: auto auto 16px;
    padding: 10px 13px 22px;
  }
  .courses-main .truck {
    position: initial;
    margin-bottom: 11px;
  }
  .courses-main .include-course {
    padding: 0;
    margin-bottom: 45px;
  }
  .courses-main .courses-block .left-block {
    max-width: initial;
    padding: 0;
    margin-bottom: 45px;
  }
  .courses-item
    .courses-block
    .line-more-info
    .courses-main
    .courses-block
    .line-more-info {
    flex-wrap: wrap;
  }
  .courses-area {
    padding-top: 64px;
  }
}
@media (max-width: 675px) {
  .courses-item .courses-block .line-more-info span {
    font-weight: 300;
    letter-spacing: 0.7px;
    line-height: 22.4px;
  }
  .courses-item .courses-block .line-more-info .item {
    display: flex;
    justify-content: space-between;
  }
  .courses-area-list .courses-item .item-course-block {
    padding-top: 44px;
  }
  .courses-area-list .courses-item {
    width: 100%;
  }
  .courses-area-list .courses-item .single-courses-box {
    margin-right: 0;
  }
}
@media (max-width: 500px) {
  .hidden_mobile
  {
    display: hidden !important;
  }
  .about-area .about-image{
    width: 100% !important;
  }

  .about-area .about-image img{
    height: 100%;
    width: 100vw;
  }
  .courses-item .courses-block .price-line {
    font-size: 16px;
    flex-direction: column;
    margin: -20px 0 0;
  }
  .courses-item .courses-block .price-line > div {
    padding-bottom: 16px;
  }
  .courses-item .courses-block .price-line > div:last-child {
    padding-bottom: 20px;
  }
  .courses-item .courses-block .price-line .price-bought {
    margin-right: 0;
  }
  .checkout-area .checkout-area-content .empty-full h3 {
    font-size: 20px;
  }
  .checkout-area .checkout-area-content .empty-full p {
    font-size: 16px;
    line-height: 22.4px;
  }
  .courses-filter {
    max-width: 500px;
  }
  .courses-main .courses-block .line-more-info {
    flex-wrap: wrap;
  }
  .button-block{
    width: 90%;
  }
  .courses-main .courses-block .line-more-info .item {
    padding: 0 14px;
  }
  .courses-item .courses-block .line-more-info .item:last-child,
  .courses-main .courses-block .line-more-info .item:last-child {
    padding-left: 0;
    padding-top: 16px;
    width: 100%;
  }
  .courses-main .include-course ul li {
    margin-bottom: 16px;
  }
  .courses-main .prices h1 {
    font-size: 40px;
    margin-bottom: 5px;
    line-height: initial;
  }
  .courses-main .courses-block .title-block img {
    display: block;
    margin-left: 18px;
    position: relative;
    top: -1px;
  }
}
@media (max-width: 340px) {
  .callphone-block {
    padding: 27px 15px 0 19px;
  }
  .courses-main .courses-block .line-more-info {
    flex-direction: column;
  }
  .courses-item .courses-block .line-more-info .item:first-child,
  .courses-main .courses-block .line-more-info .item:first-child {
    padding-top: 0;
  }
  .courses-item .courses-block .line-more-info .item,
  .courses-main .courses-block .line-more-info .item {
    padding: 16px 0;
    border: none;
    width: 100%;
  }
  .courses-main .courses-block .line-more-info .item:nth-child(2) {
    border-top: 2px solid rgba(44, 41, 42, 0.15);
    border-bottom: 2px solid rgba(44, 41, 42, 0.15);
  }
}
.empty-course {
  width: 100%;
  text-align: center;
}
.create-class-room,
.create-course,
.instructor-course,
.my-learning {
  padding: 64px 0 125px;
}
.account-header {
  display: flex;
  flex-direction: row;
  padding-bottom: 63px;
}
.account-header li:not(:last-child) {
  margin-right: 46px;
}
.account-header li a {
  font-weight: 400;
  line-height: 24px;
  position: relative;
  text-align: center;
  display: block;
}
.account-header li a.active {
  color: #fbb723;
  font-weight: 800;
}
.account-header li a.active:before {
  width: 90px;
  height: 5px;
  background: #fbb723;
  position: absolute;
  content: "";
  border-radius: 8px;
  margin: auto;
  right: 0;
  left: 0;
  display: block;
  bottom: -4px;
}
@media (max-width: 540px) {
  .account-header {
    flex-direction: column;
  }
  .account-header li:not(:last-child) {
    margin: 0 0 20px;
  }
  .account-header li:last-child {
    margin: 0;
  }
}
@media (max-width: 450px) {
  .video-area .courses-details-desc-style-two .courses-curriculum ul li {
    padding: 14px 10px;
  }
  .video-area
    .courses-details-desc-style-two
    .courses-curriculum
    ul
    li
    .courses-name {
    margin: 0 15px 0 0;
    line-height: initial;
  }
  .account-header {
    padding-bottom: 31px;
    justify-content: center;
  }
  .account-header li a.active:before {
    bottom: -5px;
  }
}
.basic-info .item-file .image-block {
  width: 212px;
  margin-right: 20px;
}
.basic-info .item-file label {
  width: calc(100% - 232px);
}
.basic-info .label-file input {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}
.basic-info .label-file .custom-file-button {
  width: 100%;
  border-radius: 8px;
  border: 2px solid #fbb723;
  color: #fbb723;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding: 13px 0;
  height: 56px;
  line-height: 24px;
  letter-spacing: 0.32px;
  transition: 0.2s ease-in;
  display: block;
  cursor: pointer;
}
.basic-info .label-file .custom-file-button:hover {
  color: #fff;
  background: #fbb723;
}
.basic-info .label-file .form-text {
  color: #2c292a;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0.8px;
}
.basic-info .content-information {
  display: flex;
  flex-direction: row;
  position: relative;
}
.basic-info .content-information:before {
  content: "";
  background: rgba(44, 41, 42, 0.05);
  height: 100%;
  width: 3px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}
.basic-info .content-information .item-form {
  margin-bottom: 32px;
}
.basic-info .content-information .social-title {
  color: #2c292a;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 1px;
}
.basic-info .content-information .left-side {
  padding-right: 64px;
}
.basic-info .content-information .right-side {
  padding-left: 64px;
}
.basic-info .button-save-bottom {
  max-width: 607px;
  margin: 64px auto auto;
  display: block;
}
@media (max-width: 1200px) {
  .basic-info .content-information .left-side {
    padding-right: 30px;
  }
  .basic-info .content-information .right-side {
    padding-left: 30px;
  }
}
@media (max-width: 900px) {
  .basic-info .item-file {
    flex-direction: column;
  }
  .animation-container_about{
    display: none;
  }
  .basic-info .item-file .image-block {
    margin: 0 0 32px;
  }
  .basic-info .item-file label {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .instructor-header-content {
    flex-direction: column;
    text-align: center;
  }
  .subscribe-content .newsletter-form .default-btn{
    margin-top: 10px;
  }
  .instructor-header-content .left-side {
    margin-bottom: 20px;
  }
  .instructor-header-content > div {
    width: 100%;
  }
  .feedback-content .title {
    font-size: 24px;
    line-height: 28.8px;
    margin-bottom: 16px;
    line-height: normal;
  }
  .feedback-content .sub-title {
    margin-bottom: 10px;
    display: block;
  }
  .feedback-content .sub,
  .subscribe-content p {
    line-height: 22.4px;
  }
  .single-feedback-item .client-info {
    margin-top: 51px;
  }
  .single-feedback-item .background-white {
    padding: 51px 18px 22px;
  }
  .get-instant {
    padding: 60px 0 0;
  }
  .subscribe-content .sub-title {
    font-size: 18px;
    line-height: 28.8px;
    display: block;
    margin-bottom: 19px;
  }
  .basic-profile-information-form,
  .create-class-room,
  .create-course,
  .instructor-course,
  .my-learning,
  .subscribe-area {
    padding: 64px 0;
  }
  .subscribe-content h2 {
    font-size: 24px;
    margin-bottom: 16px;
    line-height: 28.8px;
  }
  .subscribe-content .newsletter-form .input-newsletter {
    height: 48px;
    line-height: 48px;
    margin-bottom: 16px;
  }
  .subscribe-content .newsletter-form {
    margin-bottom: 0;
  }
  .subscribe-content .newsletter-form .default-btn {
    width: 100%;
    padding: 12px 40px;
    height: auto;
    position: initial;
  }
  .error-content h3 {
    font-size: 28px;
  }
  .login-register-block h2 {
    font-size: 24px;
  }
  .contact-info .sub-title,
  .instructor-info .sub-title,
  .work-info .sub-title {
    font-size: 18px;
    line-height: 28.8px;
    letter-spacing: 0.9px;
  }
  .contact-content h2,
  .contact-info h2,
  .instructor-info h2,
  .work-info h2 {
    font-size: 24px;
    line-height: 28.8px;
  }
  .contact-row .item:nth-child(2),
  .contact-row .item:nth-child(3) {
    width: 100%;
    padding: 0;
  }
  .contact-row .item {
    margin-bottom: 18px;
  }
  .contact-content p,
  .contact-content ul li {
    line-height: 28.8px;
  }
  .contact-content h2 + p {
    margin-bottom: 30px;
  }
  .contact-content ul {
    margin-bottom: 75px;
  }
  .contact-content p.last {
    margin-bottom: 56px;
  }
  .contact-form h2 {
    font-size: 20px;
  }
  .contact-form p,
  .contact-info p,
  .instructor-info p,
  .work-info p {
    font-size: 16px;
    line-height: 22.4px;
  }
  .basic-info .item-file {
    justify-content: center;
  }
  .basic-info .label-file .form-text {
    text-align: center;
  }
  .basic-info .item-file .image-block {
    margin: auto auto 29px;
  }
  .basic-info .content-information:before {
    display: none;
  }
  .basic-info .content-information textarea.form-control {
    height: 300px;
  }
  .basic-info .content-information {
    flex-direction: column;
  }
  .basic-info .content-information .left-side {
    margin-bottom: 44px;
  }
  .basic-info .content-information .item-form {
    margin-bottom: 29px;
  }
  .basic-info .content-information .left-side,
  .basic-info .content-information .right-side {
    padding: 0;
    width: 100%;
  }
  .bottomemail{
    padding-top: 0px !important;
  }
  .footer-content .email-bottom .social-link{
    margin-bottom: 0px;
  }
}



.dateob{
  width: 30%;
}
.dobs{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}
@media (max-width : 1000px) {
  .dobs{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
.dpbtn{
  width: 100%;
  border: 2px solid #2c292a40;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dpbtn:hover{
  border: 2px solid #2c292a40;
}
.fomlbl{
  color: #2C292A;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    display: block;
    margin-top: 10px;
}


@media (max-width: 360px){
  .feedback-area{
    padding-bottom: 80px;
  }
}

