/* buttons */
.primary-button {
  border: 2px solid #fbb723;
  background-color: #fbb723;
  color: #2c292a;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 1;
  border-radius: 16px;
  font-weight: 700;
  font-size: 24px;
  padding: 0.5rem 3.5rem;
  transition: background-color 0.3s, color 0.2s, border-color 0.1s;
  width: 300px;
  height: 68px;

}
.primary-button:hover {
  background: #2c292a;
  border-color: #2c292a;
  color: #fbb723;
}
.left-hero-block{
  margin-left: 70px;
}
.secondary-button {
  border: 2px solid #fbb723;
  background-color: #ffffff;
  color: #fbb723;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 1;
  border-radius: 16px;
  font-weight: 700;
  font-size: 24px;
  padding: 0.5rem 3.5rem;
  transition: background-color 0.3s, color 0.2s, border-color 0.1s;
  width: 300px;
  height: 68px;
}
.secondary-button:hover {
  background: #2c292a;
  border-color: #2c292a;
  color: #fbb723;
}
.w-full {
  width: 100%;
}

/* yellow bar on sbu heading */
/* .pre-yellow-bar {
  z-index: 5;
  position: relative;
}
.pre-yellow-bar::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -80%;
  width: 100%;
  height: 60%;
  background: #fbb723;
  z-index: -2;
}
.pre-yellow-bar::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10%;
  width: 100%;
  height: 50%;
  background: #ffffff;
  z-index: -1;
} */

/* Banner Animation */
.hero-banner {
  background: transparent;
}
.banner-animation-container {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* background: #fbb72360; */
}
.banner-animation-container .svg-pop-container {
  position: absolute;
  top: 0%;
  left: 13%;
  width: 30%;
  height: auto;
  z-index: 5;
  overflow: visible;
}
.banner-animation-container svg {
  width: 100%;
  height: auto;
  z-index: 6;
  overflow: visible;
}
.banner-animation-container img {
  width: 789px;
  z-index: 1;
  object-fit: contain;
}
.circularLogoContainer {
  width: 180px;
  height: 180px;
  margin: 0 auto;
  margin-bottom: 3rem;
}
.circularLogoContainer svg {
  width: 100%;
  height: 100%;
}

/* truckLoaderContainer Animation */
.truckLoaderContainer {
  height: 100vh;
  position: fixed;
  z-index: 9999;
  width: 100%;
  background:#2b292a !important;
}
.truckLoaderInner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  height: auto;
  overflow-x: hidden;
}

.truckLoaderInner svg {
  width: 35%;
  overflow: visible;
}

/* intro animation */
body {
  position: relative;
}
.no-scroll {
  overflow: hidden;
}
.introContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #2c292a;
  text-align: center;
  padding: 0 2rem;
  z-index: 99999999999;
  position: absolute;
  top: 0;
  left: 0;
}

.introInner {
  width: 50%;
}
.introInner svg {
  width: 100%;
  overflow: visible;
}

/* keyframes for drawsvg */
@keyframes smokeAnimation {
  from {
    stroke-dasharray: 0 100%;
    opacity: 0.5;
  }
  to {
    stroke-dasharray: 100% 100%;
    opacity: 1;
  }
}
.smoke {
  animation: smokeAnimation 2s linear infinite;
}

/* mobile view */
@media (max-width: 991px) {
  .edemy-nav .navbar .navbar-nav .nav-item a {
    color: #ffffff;
  }
  .edemy-nav .navbar .navbar-nav .nav-item a > div {
    color: #ffffff;
  }
  .rightimagecontent {
    height: 45vh !important;
  }
  .banner-animation-container {
    width: 100%;
    height: 100%;
  }
  .banner-animation-container img {
    width: 70%;
  }
  .banner-animation-container .svg-pop-container {
    position: absolute;
    top: 25%;
    left: 10%;
    width: 35%;
  }
  .left-hero-block .main-subtitle {
    font-size: 14px;
    max-width: 450px !important;
    font-weight: 500;
    line-height: 18px;
    padding-bottom:11px;
    padding-top: 7px;
  }
  .courses-area {
    margin-top: 0 !important;
  }
  .circularLogoContainer {
    margin-bottom: 2rem;
  }
  .main-block-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 0;
  }
  .primary-button {
    border: 2px solid #fbb723;
    font-weight: 600;
    font-size: 0.8rem;
    padding: 0.5rem 1.5rem;
    width: 45%;
  }
  .secondary-button {
    font-weight: 600;
    font-size: 0.8rem;
    padding: 0.5rem 1.5rem;
    width: 45%;
  }
  .main-left-content {
    padding-top: 1rem !important;
    padding-bottom: 0 !important;
  }
  .circularLogoContainer {
    width: 150px;
    height: 150px;
  }

  .truckLoaderInner {
    width: 100%;
  }
  .truckLoaderInner svg {
    width: 50%;
  }
}
