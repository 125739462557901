.titleheadingquiz{
    color: #FBB723;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1px;
}
.quizQuestion{
    color: #313035;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 133.333% */
letter-spacing: 1px;
margin-top: 20px;
}
.parent_quiz{
    width: 90vw;
    margin: auto;
}
.cancelbtn{
    display: flex;
width: 78px;
height: 20px;
padding: 8px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 10px;
border-radius: 8px;
background: rgba(251, 183, 35, 0.71);
color: #0A0A0A;
text-align: center;
align-items: center;
font-family: Nunito;
font-size: 10px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.topbar_ofquiz{
    display: flex;
    justify-content: space-between;
    width: 95%;
    padding-top: 20px;
    margin: auto;
}
.forquizimage{
    width:100%;
    margin-top: 20px;
}
.progressbarquiz{
    display: flex;
    margin-top: 15px;
    align-items: center;
    gap: 5px;
}
.totalnumber{
    color: var(--Text-Black, #1B0330);
text-align: right;
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 130%; /* 15.6px */
}
.parentcover{
    border-radius: 1000px;
background: var(--Gray-Line, #F0F0F0);
height: 12px;
width: 90%;
overflow: hidden;
}
.realprogressindi{
background: #FBB723;
height: 12px;
}
.optionforquiz{
    border-radius: 6px;
background: #F2F2F2;
color: #313035;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 153.846% */
letter-spacing: 1px;
margin-top: 15px;
padding: 10px;
cursor: pointer;
}
.nextbt{
    width: 90%;
    margin: auto;
 display: flex;
 align-items: center;
 justify-content: space-between;
 margin-top: 20px;
}
.submitbtn{
    width: 124px;
height: 38px;
flex-shrink: 0;
border-radius: 25px;
background: #0A0A0A;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1px;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
}
.submitbtn2{
    width: 100%;
    height: 38px;
    flex-shrink: 0;
    border-radius: 25px;
    background: #0A0A0A;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;  
}
.selected {
    background-color: #FBB723;
}
.alertitem{
    margin-top: 20px;
}
.seewhybtn{
background: #FFB800;
width: 100vw;
height: 38px;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1px;
display: flex;
align-items: center;
justify-content: center; 
margin-top: 30px;
cursor: pointer;
}
.readontext{
    color: white;
}
.selectedanswers{
    width: 90vw;
    margin: auto;
}
.readontext p{
    color: white;
}
.incorrect{
    background-color: #f5b0b3;
}
.correct{
    background-color: #b6f9c0;
}
.congratext{
    color: #313035;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 1px;
width: 100vw;
margin-top: 28px;
text-align: center;}
.centergreydiv{
    width: 80%;
    border-radius: 6px;
background: #D8D8D8;
height: 160px;
display: flex;
flex-direction: column;
text-align: center;
margin: auto;
justify-content: center;
gap: 10px;
align-items: center;
justify-items: center;
margin-top: 29px;
}
.welcometext{
    color: #0A0A0A;
text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 16.8px */
}
.quiz-result{
    background: #FAFAFA;
    min-height: 100vh;
}
.boxesforresult{
    width: 185px;
height: 77px;
flex-shrink: 0;
border-radius: 6px;
background: #FFF;
display: flex;
justify-content: center;
flex-direction: column;
gap: 7px;
}
.realtextdata{
    color: #313035;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
}
.datatitle{
    color: #A0A0A2;
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1px;
}
.flexforresults{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin: auto;
    justify-content: center;
    margin-top: 29px;
}
.donebtn{
    width: 95%;
height: 50px;
flex-shrink: 0;
border-radius: 25px;
background: #FBB723;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1px;
text-transform: uppercase;
display: flex;
align-items: center;
justify-content: center;
margin: auto;
}