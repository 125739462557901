.main-nav{
    width: 267px;
/* height: 922px; */
border-right: 1px solid #F4F4F4;
background: #FFF;
box-shadow: 0px 4px 10px 0px rgba(212, 212, 212, 0.25);
padding: 20px;
}
.navlogo{
    width: 80%;
}
.maincontainerforstudent{
    margin-top: 50px;
}
.maincontainerforstudent .courses-area{
    display: none;
}
.maincontainerforstudent .buybtn{
  display: none;  
}
.maincontainerforstudent .buybtn2{
    display: flex !important;
    width: 100%;
    height: 56px;
    padding: 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #FBB723;
    color: #2C292A;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.32px;
    margin-top: 24px;
}
.mainstudentdiv{
    min-height: 100vh;
}
.buttonsave{
    display: flex;
    justify-content: end;
}
.logo_wraper{
    width: 80%;
    margin-top: 10px;
}
.Navbar_Links{
    margin-top: 40px;
}
.mainlink{
    display: flex;
    width: 231px;
    height: 48px;
    padding: 8px;
    color: #696969;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 160%; /* 22.4px */
letter-spacing: 0.7px;

    /* flex-direction: column;
   
    align-items: flex-start; */
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}
.mainlink:hover{
   
    border-radius: 8px;
    background: rgba(251, 183, 35, 0.20);
    cursor: pointer;
}
.activateding{
    border-radius: 8px;
    background: rgba(251, 183, 35, 0.20);
    cursor: pointer;
    color: #000000;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 160%; /* 22.4px */
letter-spacing: 0.7px;
}
.activateding svg {
    stroke:#FBB723 !important
}

.main-std-dash{
    background: url(./studenbg.jpg), lightgray -0.952px -203px / 100.115% 122.556% no-repeat;  
      background-size: cover;
    width: 100%;
    /* height: 922px; */
}
.Mobile_Nav_Std{
    display: none;
}
@media (max-width: 786px) {
    .main-nav{
        display: none;
    }
    .Mobile_Nav_Std{
        display: inline-flex;
        flex-shrink: 0;
        background: #FFF;
        width: 100%;    
        padding-left: 10px;
        padding-right: 10px;
    
    }

  }
  
  .yeardropdown{
    height: 300px;
    overflow-y: auto;
  }