
  .maincontainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
  .logocon{
    height: 70vh;
  }
 
 .comlogo{
  width: 100px;
  position: absolute;
  top: 78px;
  left: 42px;
}
  
.custom-loader {
  width: 100px;
  height: 100px;
  display: grid;
  border-radius: 50%;
  -webkit-mask:radial-gradient(farthest-side,#0000 40%,#000 41%);
  background:
    linear-gradient(0deg ,#FBB72380 50%,#FBB723FF 0) center/8px 100%,
    linear-gradient(90deg,#FBB72340 50%,#FBB723BF 0) center/100% 8px;
  background-repeat: no-repeat;
  animation: s3 1s infinite steps(12);
}
.custom-loader::before,
.custom-loader::after {
   content:"";
   grid-area: 1/1;
   border-radius: 50%;
   background:inherit;
   opacity: 0.915;
   transform: rotate(30deg);
}
.custom-loader::after {
   opacity: 0.83;
   transform: rotate(60deg);
}

@keyframes s3 {
  100% {transform: rotate(1turn)}
}