.coverofreviewslider {
    padding: 24px;
    position: relative;
    bottom: 44px;
}

.coverofvideothumbnail {
    cursor: pointer;
}
.cover_of_classA{
    height: 697px;
}
.coverofcourselreview {
    max-width: 556px;
    position: relative;
    top: 111px;}

.coverofsponer4 {
    padding: 20px;
}

.reviewcontent img {
    width: 100%;
    /* height: 1311px; */
}

.imageoftruck {
    width: 40px;
    height: 40px;
}

.coverofco {
    padding: 24px;
    max-width: 516px;
}

.cover_of_classa_header {
    margin-top: 30px;
    background-image: url(../Reviews/background\ of\ class\ a\ landng.png);
    background-size: cover;
    max-width: 100vw;
    height: 351px;
}

.video_section_desktop {
    max-width: 564px;
}

.course_title {
    color: #2C292A;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.text_content {
    color: rgba(44, 41, 42, 0.70);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    width: 187px;
}

.text_content2 {
    color: rgba(44, 41, 42, 0.70);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    width: 228px;
}

.Course_content {
    padding: 20px;
}

.content_section {
    display: flex;
    gap: 8px;
    /* align-items: center; */
}

.cover_of_content {
    padding-top: 16px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.Header_title_classa {
    display: flex;
    gap: 24px;
    margin-top: 50px;
}

.title_of_classa {
    color: #FFF;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.cover_of_fllowup {
    width: 1008px;
    margin: auto;
}

.inside_of_header {
    max-width: 1008px;
    display: flex;
    gap: 61px;
    margin: auto;
}

.span_intro_text {
    color: #D1D1D1;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 22.4px */

}
.mobiletruck{
    width: 228px;
    height: 155px;
}
.paraofclassa{
    font-weight: bold;
    font-family: Inter;
    font-size: 14px;
    max-width: 100%;
    line-height: 160%;
}
.thoery{
    font-size: 16px;
    font-weight: bold;
    font-family: Inter;
}

.courseltitle {
    color: #2C292A;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.titlecarousel {
    height: 65px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 2px solid #EEE;
    margin-bottom: 16px;
    display: flex;
   gap: 32px;
    align-items: center;
    padding: 10px;
}

.labeloftime {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: rgba(251, 183, 35, 0.25);
    width: 74px;
    height: 26px;
    color: #2C292A;
    font-family: "Nunito Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    /* 200% */
}

.coverofallreview {
    position: relative;
    bottom: 298px;
}

.coverofsponer3 {
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    background: #FDFDFD;
}

.courseltitle2 {
    color: #2C292A;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    display: flex;
    justify-content: center;
}

.Card_section_of_header {
    margin-top: 65px;
}

.text_under_classa_card {
    color: #2C292A;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    text-align: center;
    margin-top: 24px;
}

.classacard {
    border-radius: 8px;
    border: 1px solid #EEE;
    background: #FFF;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
    position: relative;
    padding: 16px;
    width: 383px;
}

.learntext {
    color: #FFF;
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    /* 25.6px */
    letter-spacing: 0.8px;
    position: relative;
    top: 84px;
    right: 10px;
}

.headerofclassa {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.classtitle {
    color: #2D2727;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.reviewcount {
    color: #2D2727;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 16.8px */
}

.startsreview {
    display: flex;
    justify-content: center;
}

.priceofc {
    color: #2D2727;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
}

.coverofpricebuttons .priceofc {
    position: relative;
    right: 0px;
}

.reviewsectionclass {
    display: flex;
    gap: 5px;
    align-items: center;
}

.coverofpricebuttons {
    display: flex;
    align-items: center;
    gap: 48px;
    margin-top: 16px;
}

.coverofpricebuttons .button_a {
    margin-top: 20px;
}

.certificationbtn {
    display: flex;
    border-top: 1px solid #EEE;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #EEE;
    margin-top: 36px;
}

.certified:first-child {
    border-right: 1px solid #EEE;
}

.certified:nth-child(2) {
    padding-left: 16px;
}

.certified {
    width: 50%;
    color: #2C292A;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 10px;
}

.certified svg {
    width: 24px;
    height: 24px;
}

.button_a {
    display: flex;
    width: auto;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #F1F1F1;
    color: #FF3030;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    border-radius: 4px;
    background: #FFD6D6;
    padding: 5px;
    /* 16.8px */
}

.Main_price_area {

    color: #2C292A;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 67.2px */
    position: relative;
    bottom: 30px;
}

.classacard .selectedtest {
    margin-top: 0px;
}

.watchdetail {
    color: #2C292A;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    /* 22.4px */
    letter-spacing: 0.7px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    cursor: pointer;
    margin-top: 16px;
    text-decoration: underline;
}

@keyframes switch-animation {
    0% {
        opacity: 1;
    }

    /* Initial state - SVG1 fully visible */
    50% {
        opacity: 0;
    }

    /* Halfway point - SVG1 fades out, SVG2 fades in */
    100% {
        opacity: 1;
    }

    /* Final state - SVG2 fully visible */
}

.animation-container {
    position: relative;
    left: 42%;
    bottom: 250px;
    max-width: 35vw;
}

.svg1,
.svg2 {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    /* Initially hidden */
}

.svg1 {
    animation: switch-animation 2s infinite;
    /* Adjust animation duration as needed */
}

.svg2 {
    animation: switch-animation 2s infinite alternate;
    /* Adjust animation duration as needed */
    animation-delay: 1s;
    /* Start after 1 second to create alternating effect */
}

.learntext {
    right: 44px;
}
.for_destop_version_classa .coverofsponer4{
display: none;
}
.for_destop_version_classa{
    width: 1008px;
    margin: auto;
}
.for_destop_version_classa .coverofreviewslider{
    margin-top: 50px;
}
.for_destop_version_classa .slick-dots.slick-dots-bottom{
 display: none !important;
}
.for_destop_version_classa .coverofsponer3{
    display: none;
}
.for_destop_version_classa .coverofsponer2{
    display: none;
}
.cover_of_Graduate{
padding: 20px;
border-radius: 8px;
border: 2px solid #EEE;
gap: 12px;
width: 564px;
}
.cover_of_Titles{
    max-width: 570px;
    padding-left: 30px;
}
.explain_text{
    color: rgba(44, 41, 42, 0.70);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
margin-top: 8px;
}
.cover_of_Graduate{
    position: relative;
    bottom: 50px;
}
.coverofallcour {
    display: flex;
    align-items: center;
    gap: 10px;
    color: rgba(44, 41, 42, 0.70);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    /* 22.4px */
    letter-spacing: 0.7px;
}
.mobileallphone .circularLogoContainer{
display: none;
}
.mobile_cards .circularLogoContainer{
    display: none;
    }
.Main_div_of_cards{
    display: flex;
    width: 988px;
height: 445px;
border-radius: 16px;
border: 2px solid #ECA50C;
background: radial-gradient(93.32% 76.76% at 64.42% 24.6%, #FFE2A1 0%, #FFCB5A 0.01%, #E9A208 100%);
}
.coverofcircularlogo{
    position: relative;
    top: 133px;
    left: 20px;
    width: 132px;
}
.coverofcircularlogo .circularLogoContainer svg{
    width: 132px;
}
.coverofCarda {
    position: relative;
    bottom: 100px;
}
.tag_image{
    position: relative;
    bottom: 80px;
    left: 20px;
}
.content_of_carda{
    padding: 40px;
    padding-top: 0px;
    border-right: 1px solid #E2AA30;
    width: 530px;
    margin-top: 80px;
}
.desc_class_a {
    display: flex;
    align-items: center;
    gap: 10px;
}
.titletextof_card{
    color: #2C292A;
font-family: Inter;
font-size: 40px;
font-style: normal;
font-weight: 800;
line-height: 120%; /* 48px */
width: 438px;
}
.descontent {
    color: #2C292A;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 19.2px */

}
.explain_text{
    color: rgba(44, 41, 42, 0.60);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 22.4px */
width: 435px;
margin-top: 40px;
}
.Left_side_cardsa{
    margin-top: 60px;
    padding: 40px;
}
.BuyNowforCars{
    color: #FFF;
font-family: Heebo;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 21.6px */
display: flex;
width: 255px;
padding: 16px 29px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
background: linear-gradient(96deg, #030102 0%, #2C292A 100%);
margin-top: 50px;
}
.mobileallphone{
    position: relative;
    bottom: 100px;
}
.discountedprice svg{
    position: relative;
    top: 23px;
    left: 10px;
}
.cover_for_footer{
margin-top: -130px;
}

.Main_price_area2 {
    color: #2C292A;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    position: relative;
    
}
.coverofdis .call-to-phone-block{
  margin-top: 80px;
}
.truckforclassa{
    position: absolute;
    left: 205px;
    height: 131px;
    width: 221px;
    top: 46px;
}
.dollare{
    position: relative;
    right: 80px;
}
.svg-1{
    position: relative;
    bottom: 2px;
}
@media (max-width:500px) {
    .cover_of_classA {
        height: auto;
        border-radius: 16px;
        border: 2px solid #ECA50C;
        background: radial-gradient(93.32% 76.76% at 64.42% 24.6%, #FFE2A1 0%, #FFCB5A 0.01%, #E9A208 100%);
        width: 85%;
        margin: auto;
        padding: 24px;
        margin-bottom: 16px;

    }
    .cover_of_Titles{
        width: auto;
        padding: 20px;
        position: relative;
        bottom: 227px;
    }
    .desc_class_a {
        width: 238px;
        display: flex;
        align-items: center;
        margin-top: 68px;

    }

    .descontent {
        width: 100%;
        color: #2C292A;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 19.2px */

    }

    .animation-container {
        position: relative;
        left: 41%;
        bottom: 224px;
        max-width: 35vw;
    }

    .title_class_a {
        color: #2C292A;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 140%;
        /* 33.6px */
        width: 251px;
        margin-top: 16px;
    }

    .descofclassAtitle {
        color: rgba(44, 41, 42, 0.60);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 22.4px */
        width: 246px;
        margin-top: 16px;
    }

    .bottomborder {
        margin-top: 26px;
        border-bottom: 1px solid #E2AA30;

    }

    .button_a {
        margin-top: 26px;
        display: flex;
        width: auto;
        height: 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 4px;
        background: #c9c7c7 !important;
        color: #E32C39;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        padding: 5px;
        /* 16.8px */
    }
    .coverofpricebuttons{
        margin-top: 0px !important;
        position: relative;
        left: 20px !important;
    }
    .priceofc svg{
        position: relative;
        bottom: 1px;
    }

    .priceofclassa {
        margin-top: 16px;
        color: rgba(44, 41, 42, 0.50);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 22.4px */
        position: relative;
        bottom: 25px;
    }

    .linesvg {
        position: relative;
        top: 12px;
    }

    .Main_price_area {

        color: #2C292A;
        font-family: Inter;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        /* 67.2px */
        position: relative;
        bottom: 7px;
        text-align: center;
    }

    .BuyNowforclassA {
        display: flex;
        width: 100%;
        padding: 16px 29px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: linear-gradient(96deg, #030102 0%, #2C292A 100%);
        color: #FFF;
        font-family: Heebo, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 21.6px */
    }

    .mobile_cards .anothertitleforcards {
        display: none;
    }

    .mobile_cards .maincontentslider {
        margin-bottom: 0px;
    }

    .imageforbannerclassa {
        position: relative;
        top: 69px;
        left: 40px;
        max-width: 80vw;
    }

    .coverofco {
        padding: 24px;
        position: relative;
        bottom: 234px;

    }


    .courseltitle {
        color: #2C292A;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .titlecarousel {
        height: 65px;
        flex-shrink: 0;
        border-radius: 16px;
        border: 2px solid #EEE;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
    }

    .labeloftime {
        display: flex;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: rgba(251, 183, 35, 0.25);
        width: 63px;
        height: 26px;
        color: #2C292A;
        font-family: "Nunito Sans", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px;
        /* 200% */
    }

    .coverofallreview {
        position: relative;
        bottom: 68px;
    }

    .coverofallcour {
        display: flex;
        align-items: center;
        gap: 10px;
        color: rgba(44, 41, 42, 0.70);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        /* 22.4px */
        letter-spacing: 0.7px;
        width: 211px;
    }

    .topersponsor {
        padding: 24px;
        position: relative;
        bottom: 77px;

    }

    .coverofsponer {

        padding: 20px;
        text-align: center;
        gap: 24px;
        border-radius: 8px;
        border: 2px solid #EEE;
        background: #FDFDFD;
        padding: 20px;
    }

    .descofpartner {
        color: rgba(44, 41, 42, 0.70);
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 19.6px */
        display: flex;
        justify-content: center;
        margin-top: 5px;

    }

    .inercon {

        width: 254px;
    }

    .sponsorimage {
        margin-top: 15px;
    }

    .coverofsponer2 {
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        border-radius: 8px;
        border: 2px solid #EEE;
        background: #FDFDFD;
    }

    .listcom {
        display: flex;
        gap: 10px;
        color: rgba(44, 41, 42, 0.70);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 19.6px */
        align-items: center;
        margin-top: 20px;
    }

    .coverofreviewslider {
        padding: 24px;
        position: relative;
        bottom: 44px;
    }

    .coverofsponer4 {
        padding: 20px;
        position: relative;
        top: 127px;
    }

    .reviewcontent img {
        width: 100%;
        /* height: 1311px; */
    }

    .coverofsponer3 {
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        background: #FDFDFD;
    }

    .courseltitle2 {
        color: #2C292A;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-decoration-line: underline;
        display: flex;
        justify-content: center;
    }

    .coverofclassvideo {
        width: 100%;

    }

    .coverofclassvideo img {
        width: 100%;
    }



    .coverofclassvideo .ant-modal-content {
        background-color: black;
    }

    .classacard {
        border-radius: 24px;
        border: 1px solid #EEE;
        background: #FFF;
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
        position: relative;
        bottom: 74px;
        padding: 16px;
        width: auto;
    }

    .learntext {
        color: #FFF;
        color: #FFF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
        /* 25.6px */
        letter-spacing: 0.8px;
        position: relative;
        top: 84px;
        right: 10px;
    }

    .headerofclassa {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }

    .classtitle {
        color: #2D2727;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .reviewcount {
        color: #2D2727;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        /* 16.8px */
    }

    .startsreview {
        display: flex;
        justify-content: center;
    }

    .priceofc {
        color: #2D2727;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 22.4px */
    }

    .coverofpricebuttons .priceofc {
        position: relative;
        right: 0px;
    }
.pricealert{
position: relative;
right: 63px;
color: #FF3030;
font-weight: bold;
}
    .reviewsectionclass {
        display: grid;
        row-gap: 5px;
    }
.linesvg1{
    position: relative;
    bottom: 2px;
}
    .coverofpricebuttons {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: center;
    }

    .coverofpricebuttons .button_a {
        margin-top: 0px;
    }

    .certificationbtn {
        display: flex;
        border-top: 1px solid #EEE;
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #EEE;
        margin-top: 36px;
    }

    .certified:first-child {
        border-right: 1px solid #EEE;
    }

    .certified:nth-child(2) {
        padding-left: 16px;
    }

    .certified {
        width: 50%;
        color: #2C292A;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .certified svg {
        width: 24px;
        height: 24px;
    }



    .forsmallermobile {
        display: none;
    }

    @media (max-width:500px) {
        .coverformobileversionofclassa .footer-area {
            margin-top: -40px;
        }

        .forlargerdes {
            display: none;
        }

        .forsmallermobile {
            display: block;
            max-width: 290px;
        }
    }

    .coverofrevi {
        position: relative;
        bottom: 177px;
    }

    .mobile_cards {
        position: relative;
        bottom: 0px;
    }

    .classacard .selectedtest {
        margin-top: 0px;
    }
}

@media (max-width:415px) {
    .classacard {
        bottom: 79px;

    }

    .coverofreviewslider {
        bottom: 33px;
    }

    .animation-container {
        bottom: 191px;
    }
}

@media (max-width:400px) {

    .animation-container {
        bottom: 194px;
    }
}

@media (max-width:380px) {
    .classacard {
        bottom: 60px;

    }

    .animation-container {
        bottom: 200px;
    }
}