.bg-1cab94 {
  background-color: #FFC32B;
}

.w-30px {
  width: 30px;
}

.w-35px {
  width: 35px;
}

.w-40px {
  width: 40px;
}

.w-45px {
  width: 45px;
}

.w-50px {
  width: 50px;
}

.w-55px {
  width: 55px;
}

.max-300px {
  max-width: 300px;
}

.max-height-60 {
  max-height: 60px;
  overflow-y: auto;
}

/* Admin Area Style
--------------------------------------*/
.main-content .container-fluid {
  padding-left: 40px;
  padding-right: 40px;
}

.main-content-box {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 15px;
}

/* Admin side nav */
.side-nav-wrapper {
  background-color: #f7f9fa;
  height: 100%;
}
.side-nav-wrapper .close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #FFC32B;
  color: #fff;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 35px;
  font-size: 22px;
  border-radius: 100px;
}

.side-nav {
  padding: 40px 30px;
}
.side-nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.side-nav ul li a {
  font-size: 17px;
  padding: 5px 20px;
  display: block;
}
.side-nav ul li a.active {
  font-weight: bold;
}

.sidebar-menu-button {
  background-color: #FFC32B;
  cursor: pointer;
  color: #fff;
  padding: 8px 15px;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  margin-top: 30px;
}

/* End Admin side nav */
/* info-box-card */
.info-box-card {
  margin-bottom: 25px;
  padding: 30px 20px;
  background-color: #f7f9fa;
  text-align: center;
  transition: 0.5s;
  border-radius: 10px;
}
.info-box-card i {
  display: inline-block;
  background-color: #FFC32B;
  width: 60px;
  height: 60px;
  line-height: 60px;
  color: #fff;
  font-size: 34px;
  margin-bottom: 20px;
  border-radius: 100%;
}
.info-box-card h1 {
  font-weight: bold;
}
.info-box-card:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

/* End info-box-card */
/* End Admin Area Style
--------------------------------------*/
/* Instructor Area Style
--------------------------------------*/
.box-shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

/* Instructor Create Courses Card */
.instructor-create-courses-card {
  padding: 30px;
  margin-bottom: 30px;
}
.instructor-create-courses-card .icon {
  margin-bottom: 15px;
}
.instructor-create-courses-card .icon i {
  font-size: 50px;
  color: #FFC32B;
}
.instructor-create-courses-card h3 {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 25px;
}
.instructor-create-courses-card p {
  margin-bottom: 20px;
}

.action-dropdown {
  position: absolute;
  top: 10px;
  right: 10px;
}
.action-dropdown .icon {
  background-color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 46px;
  cursor: pointer;
  font-size: 22px;
  border-radius: 3px;
  transition: 0.5s;
}
.action-dropdown .icon:hover {
  background-color: #FFC32B;
  color: #ffffff;
}
.action-dropdown .dropdown-menu {
  display: block;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 150px;
  border: 1px solid #eee;
}
.action-dropdown .dropdown-menu .dropdown-item {
  position: relative;
  padding: 8px 15px 8px 40px;
  font-size: 15px;
}
.action-dropdown .dropdown-menu .dropdown-item i {
  position: absolute;
  left: 15px;
  top: 9px;
  font-size: 18px;
}
.action-dropdown .dropdown-menu .dropdown-item:active {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.action-dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

/* End instructor Area Style
-----------------------------------------*/
/* Create Course Style
--------------------------------------*/
.w-100px {
  width: 100px;
}

.file-control {
  line-height: 48px;
}

/* End Create Course Style
--------------------------------------*/
/* Profile Style
--------------------------------------*/
.nav-style1 {
  list-style-type: none;
  margin-bottom: 30px;
  padding: 0;
  border-bottom: 1px solid #eee;
}
.nav-style1 li {
  display: inline-block;
  margin-right: 20px;
  padding-bottom: 10px;
}
.nav-style1 li:last-child {
  margin-right: 0;
}
.nav-style1 li a {
  font-weight: bold;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 2px solid transparent;
  font-size: 16px;
}
.nav-style1 li a.active, .nav-style1 li a:hover {
  border-bottom: 2px solid #FFC32B;
  color: #FFC32B;
}

.mw-200px {
  max-width: 200px;
}

/* End Profile Style
--------------------------------------*/
/* Start Video Area Style
--------------------------------------*/
.video-area .container-fluid {
  padding-left: 40px;
  padding-right: 40px;
}

.video-sidebar .course-video-list .title {
  font-weight: bold;
  font-size: 18px;
}

/* End Video Area Style
--------------------------------------*/
/* Start Responsive Style
-----------------------------------------*/
/* Smartphones (landscape) ----------- */
@media only screen and (max-width: 767px) {
  /* Admin Area Style
  --------------------------------------*/
  .main-content .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .main-content-box {
    padding-left: 0;
    padding-top: 25px;
  }
  /* Side nav wrapper */
  .side-nav ul li a {
    padding: 5px 0;
  }
  .side-nav-wrapper {
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 99999;
    width: 300px;
    overflow-y: auto;
    transition: 0.5s;
  }
  .side-nav-wrapper.active {
    left: 0;
  }
  .side-nav-wrapper .sticky-box {
    position: initial !important;
  }
  /* End Side nav wrapper */
  /* End Admin Area Style
  --------------------------------------*/
  /* Instructor Area Style */

  /* End instructor Area Style */
  /* Start Video Area Style
  --------------------------------------*/
  .video-area .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .video-area .video-content .courses-details-desc-style-two {
    border-bottom: 1px solid #eee;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
  /* End Video Area Style
  --------------------------------------*/
  .nav-style1 {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* Admin Area Style
  --------------------------------------*/
  .main-content-box {
    padding-left: 0;
  }
  /* Side nav wrapper */
  .side-nav ul li a {
    font-size: 16px;
    padding: 5px 0;
  }
  /* End Admin Area Style
  --------------------------------------*/
  /* Instructor Area Style */
  .instructor-create-courses-card h3 {
    font-size: 22px;
  }
  /* End instructor Area Style */
  /* Start Video Area Style
  --------------------------------------*/
  .video-area .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
  /* End Video Area Style
  --------------------------------------*/
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* Admin Area Style
  --------------------------------------*/
  /* Side nav wrapper */
  .side-nav ul li a {
    font-size: 16px;
    padding: 5px 0;
  }
  /* End Admin Area Style
  --------------------------------------*/
  /* Instructor Area Style */
  .instructor-create-courses-card h3 {
    font-size: 22px;
  }
  /* End instructor Area Style */
  /* Start Video Area Style
  --------------------------------------*/
  .video-area .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
  /* End Video Area Style
  --------------------------------------*/
}
@media only screen and (min-width: 1920px) {
  /* Start Video Area Style
  --------------------------------------*/
  .video-area .container-fluid {
    padding-left: 120px;
    padding-right: 120px;
    max-width: 1920px;
  }
  /* End Video Area Style
  --------------------------------------*/
  /* Admin Area Style
  --------------------------------------*/
  .main-content .container-fluid {
    padding-left: 120px;
    padding-right: 120px;
  }
  /* End Admin Area Style
  --------------------------------------*/
}

